import React, { useEffect, useState } from 'react';
import { IconInformation } from '../../constants/Icon';
import FormulaForm from './FormulaForm';
import SettingODM from './SettingODM';

export default function DistributionFormulaForm({ valueOdmWeightAge }) {
  const [activeAvailability, setActiveAvailability] = useState({});
  const [activeDistance, setActiveDistance] = useState({});
  const [activeIdleTime, setActiveIdleTime] = useState({});
  const [valueAvailability, setValueAvailability] = useState();
  const [valueDistance, setValueDistance] = useState();
  const [valueIdleTime, setValueIdleTime] = useState();
  const [valueQualified, setValueQualified] = useState();
  const [error, setError] = useState('');
  const [errorAvailability, setErrorAvailability] = useState('');
  const [errorDistance, setErrorDistance] = useState('');
  const [errorIdleTime, setErrorIdleTime] = useState('');
  const [errorQualified, setErrorQualified] = useState('');
  const [openInformation, setOpenInformation] = useState(false);
  const [openFormulaForm, setOpenFormulaForm] = useState(false);
  const [openSettingOrderDistribution, setOpenSettingOrderDistribution] = useState(false);

  const handlerChange = (e, setEnable, setErrorInput) => {
    setErrorInput('');
    const newValue = {
      [e.target.name]: e.target.checked,
    };
    setEnable(newValue);
  };

  const handleInput = (e, setEnable, setErrorInput) => {
    setError('');
    setErrorQualified('');
    setEnable(e.target.value);
    if (!e.target.value) {
      return setErrorInput('Value must be a number');
    }
    setErrorInput('');
  };

  const onBlur = () => {
    setOpenInformation(false);
  };

  const handleSubmit = () => {
    const availability = activeAvailability.Availability ? valueAvailability : 0;
    const distance = activeDistance.Distance ? valueDistance : 0;
    const idle_time = activeIdleTime.Idle_time ? valueIdleTime : 0;

    const qualified_limit = Number(availability) + Number(distance) + Number(idle_time);
    if (qualified_limit !== 100) {
      setError('Total percentage of Availability, Distance, Idle Time must be equal to 100%');
    }
    if (valueQualified > 100 || valueQualified < 0) {
      setErrorQualified('Qualified limit must be between 0 and 100');
    }
    if (qualified_limit !== 100 || valueQualified > 100 || valueQualified < 0) return;
    setOpenFormulaForm(true);
    setError('');
    setErrorAvailability('');
    setErrorDistance('');
    setErrorIdleTime('');
    setErrorQualified('');
  };

  useEffect(() => {
    const checkAvailability = valueOdmWeightAge?.availability_weight !== 0;
    const checkDistance = valueOdmWeightAge?.distance_weight !== 0;
    const checkIdleTime = valueOdmWeightAge?.idle_time_weight !== 0;
    setActiveAvailability({ Availability: checkAvailability });
    setActiveDistance({ Distance: checkDistance });
    setActiveIdleTime({ Idle_time: checkIdleTime });
    setValueAvailability(valueOdmWeightAge?.availability_weight);
    setValueDistance(valueOdmWeightAge?.distance_weight);
    setValueIdleTime(valueOdmWeightAge?.idle_time_weight);
    setValueQualified(valueOdmWeightAge?.qualified_limit);
  }, [valueOdmWeightAge]);

  return (
    <div className="p-4 rtl">
      <div className="flex justify-between">
        <div>
          <h3 className="font-semibold text-2xl mb-1">Calculating field</h3>
          <p className="mb-6">
            Total percentage of all active calculating fields must be equal to 100%
          </p>
        </div>
        <button
          onClick={() => setOpenSettingOrderDistribution(true)}
          className="h-[35px] border bg-gray-100 hover:bg-gray-300 font-medium rounded-lg text-xl px-3 py-2"
        >
          Setting Order distribution
        </button>
      </div>
      {error && (
        <div className="flex justify-between">
          <p className="text-red-600 w-fit flex gap-2 items-center mb-6 p-2 rounded-lg bg-red-200">
            <IconInformation />
            {error}
          </p>
          <p></p>
        </div>
      )}
      <ul className="space-y-3 mb-10">
        <li className="items-start flex gap-6 font-semibold">
          <p className="min-w-[130px]">Title</p>
          <p className="w-full">Value</p>
        </li>
        <li className="flex items-start gap-1">
          <p className="min-w-[130px] mb-[5px] mt-2">Availability</p>
          <div className="flex w-1/2 items-start gap-6">
            <div className="w-1/2">
              <div
                className={`${!activeAvailability.Availability && 'bg-gray-100'} ${
                  ((activeAvailability.Availability && error) || errorAvailability) &&
                  'border-red-600'
                } flex justify-between px-3 py-2 mb-[5px] rounded-md border items-center`}
              >
                <input
                  value={valueAvailability}
                  onChange={(e) => handleInput(e, setValueAvailability, setErrorAvailability)}
                  disabled={!activeAvailability.Availability}
                  type="number"
                  className="w-full outline-none bg-transparent"
                />
                <p
                  className={`${
                    activeAvailability.Availability ? 'text-[#3B82F6]' : 'text-[#9CA3AF]'
                  } `}
                >
                  %
                </p>
              </div>
              {errorAvailability && <p className="text-red-600 text-center">{errorAvailability}</p>}
            </div>
            <div className="flex items-center gap-2">
              <label className="switch">
                <input
                  checked={activeAvailability.Availability}
                  type="checkbox"
                  name="Availability"
                  onChange={(e) => handlerChange(e, setActiveAvailability, setErrorAvailability)}
                />
                <span className="slider round"></span>
              </label>
              <p
                className={`mb-[5px] ${
                  activeAvailability.Availability ? 'text-[#3B82F6]' : 'text-[#9CA3AF]'
                } `}
              >
                {activeAvailability.Availability ? 'Active' : 'Inactive'}
              </p>
            </div>
          </div>
        </li>

        <li className="flex items-start gap-1">
          <p className="min-w-[130px] mb-[5px] mt-2">Distance</p>
          <div className="flex w-1/2 items-start gap-6">
            <div className="w-1/2">
              <div
                className={`${!activeDistance.Distance && 'bg-gray-100'} ${
                  ((activeDistance.Distance && error) || errorDistance) && 'border-red-600'
                } flex  justify-between px-3 py-2 mb-[5px] rounded-md border items-center`}
              >
                <input
                  value={valueDistance}
                  onChange={(e) => handleInput(e, setValueDistance, setErrorDistance)}
                  disabled={!activeDistance.Distance}
                  type="number"
                  className="w-full outline-none bg-transparent"
                />
                <p className={`${activeDistance.Distance ? 'text-[#3B82F6]' : 'text-[#9CA3AF]'} `}>
                  %
                </p>
              </div>
              {errorDistance && <p className="text-red-600 text-center">{errorDistance}</p>}
            </div>
            <div className="flex items-center gap-2">
              <label className="switch">
                <input
                  checked={activeDistance.Distance}
                  type="checkbox"
                  name="Distance"
                  onChange={(e) => handlerChange(e, setActiveDistance, setErrorDistance)}
                />
                <span className="slider round"></span>
              </label>
              <p
                className={`mb-[5px] ${
                  activeDistance.Distance ? 'text-[#3B82F6]' : 'text-[#9CA3AF]'
                } `}
              >
                {activeDistance.Distance ? 'Active' : 'Inactive'}
              </p>
            </div>
          </div>
        </li>

        <li className="flex items-start gap-1">
          <p className="min-w-[130px] mb-[5px] mt-2">Idle time</p>
          <div className="flex w-1/2 items-start gap-6">
            <div className="w-1/2">
              <div
                className={`${!activeIdleTime.Idle_time && 'bg-gray-100'} ${
                  ((activeIdleTime.Idle_time && error) || errorIdleTime) && 'border-red-600'
                } flex justify-between px-3 py-2 mb-[5px] rounded-md border items-center`}
              >
                <input
                  value={valueIdleTime}
                  onChange={(e) => handleInput(e, setValueIdleTime, setErrorIdleTime)}
                  disabled={!activeIdleTime.Idle_time}
                  type="number"
                  className="w-full outline-none bg-transparent"
                />
                <p className={`${activeIdleTime.Idle_time ? 'text-[#3B82F6]' : 'text-[#9CA3AF]'} `}>
                  %
                </p>
              </div>
              {errorIdleTime && <p className="text-red-600 text-center">{errorIdleTime}</p>}
            </div>
            <div className="flex items-center gap-2">
              <label className="switch">
                <input
                  checked={activeIdleTime.Idle_time}
                  type="checkbox"
                  name="Idle_time"
                  onChange={(e) => handlerChange(e, setActiveIdleTime, setErrorIdleTime)}
                />
                <span className="slider round"></span>
              </label>
              <p
                className={`mb-[5px] ${
                  activeIdleTime.Idle_time ? 'text-[#3B82F6]' : 'text-[#9CA3AF]'
                } `}
              >
                {activeIdleTime.Idle_time ? 'Active' : 'Inactive'}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <div className="flex items-center gap-1 pb-6 border-b mb-5">
        <div className="flex min-w-[130px] mb-[5px] items-center gap-1 font-bold">
          <p>Qualified limit</p>
          <div className="relative flex items-center">
            <button onClick={() => setOpenInformation(!openInformation)} onBlur={onBlur}>
              <IconInformation />
            </button>

            {openInformation && (
              <div className="px-3 py-2 w-[300px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                The admin are allow to set a qualified limit: For example If the score is below 70,
                that shipper is not qualified to receive an incoming order notification
              </div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <div className="w-1/2">
            <div
              className={`px-3 py-2 mb-[5px] rounded-md border items-center ${
                errorQualified && 'border-red-600'
              }`}
            >
              <input
                value={valueQualified}
                type="number"
                className="w-full outline-none"
                onChange={(e) => handleInput(e, setValueQualified, setErrorQualified)}
              />
            </div>
            {errorQualified && <p className="text-red-600 text-center">{errorQualified}</p>}
          </div>
        </div>
      </div>

      <button
        onClick={handleSubmit}
        className="h-[35px] mb-4 focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-1 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-700 font-medium rounded-lg text-xl px-12 py-2.5"
      >
        Save formula
      </button>
      <p>Your changes will be applied immediately for incoming orders :Note</p>
      <FormulaForm
        isOpen={openFormulaForm}
        setIsOpen={setOpenFormulaForm}
        valueAvailability={valueAvailability}
        valueDistance={valueDistance}
        valueIdleTime={valueIdleTime}
        valueQualified={valueQualified}
        activeAvailability={activeAvailability}
        activeDistance={activeDistance}
        activeIdleTime={activeIdleTime}
      />
      <SettingODM
        isOpen={openSettingOrderDistribution}
        setIsOpen={setOpenSettingOrderDistribution}
      />
    </div>
  );
}
