import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

toast.configure();

export default function SubscriptionActions(props) {
  const { handlerModal, data, handleOpenDelete } = props;
  const { t } = useTranslation();
  return (
    <>
      <div style={styles.container}>
        <button
          style={styles.edit}
          onClick={() => {
            handlerModal(data);
          }}
        >
          {t('edit_data')}
        </button>

        <button
          style={styles.delete}
          onClick={() => {
            handleOpenDelete(data);
          }}
        >
          {t('delete')}
        </button>
        {data?.status === 'ACTIVE' && (
          <Link
            to={{
              pathname: `/subscriptions-assign/company-name/${data?.company_info?.name}/company-id/${data.company_id}/subscription-id/${data._id}/drivers`,
            }}
          >
            <button style={styles.subscription}>{t('driver_subscriptions')}</button>
          </Link>
        )}
      </div>
    </>
  );
}

const styles = {
  container: {
    padding: 20,
    backgroundColor: '#FCF5F2',
  },

  edit: {
    backgroundColor: '#E0EFF7',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#342B40',
    fontSize: 12,
    borderRadius: 6,
    cursor: 'pointer',
    border: '1px solid #E0EFF7',
  },
  delete: {
    backgroundColor: '#C32E52',
    border: '1px solid #C32E52',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 6,
    cursor: 'pointer',
  },
  subscription: {
    backgroundColor: '#87A922',
    border: '1px solid #A4CE95',
    padding: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    color: '#fff',
    fontSize: 12,
    borderRadius: 6,
    cursor: 'pointer',
  },
};
