import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Chevron } from '../../../../constants/Icon';
import MonthYearPicker from '../../../MonthYearPicker';
import SelectOption from './SelectOption';
import { useTranslation } from 'react-i18next';

export default function SelectDate({ onChangeDate, date, selectedOption, setSelectedOption }) {
  const currentYear = new Date();
  const minYear = 2017;
  const { t } = useTranslation();

  return (
    <div className="w-[100px] h-20 ">
      <Popover className="relative">
        {({ open }) => {
          const checkValueOpen = open ? '' : 'text-opacity-90';
          return (
            <>
              <Popover.Button
                className={`
                ${checkValueOpen} group inline-flex items-center rounded-md bg-orange-700 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <div className="flex flex-row bg-gray-100 px-6 py-3 rounded-md">
                  <div className="text-black font-semibold text-2xl pr-4 flex items-center justify-center">
                    {t('filter')}
                  </div>
                  <Chevron />
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="relative z-10 mt-3 w-[200px] max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                  <div className="bg-white z-20 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="bg-gray-50 pb-8 pt-8 z-20 ">
                      <div className="mb-2 px-10">
                        <div className="z-20">
                          <SelectOption value={selectedOption} setSelected={setSelectedOption} />
                        </div>
                      </div>
                      <div className="flex w-full justify-center items-center">
                        <MonthYearPicker
                          selectedOption={selectedOption}
                          selectedMonth={date.month}
                          selectedYear={date.year}
                          selectedWeek={date.amount}
                          minYear={minYear}
                          maxYear={currentYear.getUTCFullYear()}
                          onChangeYear={(year) => onChangeDate('year', year)}
                          onChangeMonth={(month) => onChangeDate('month', month)}
                          onChangeWeek={(amount) => onChangeDate('amount', amount)}
                        />
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </div>
  );
}
