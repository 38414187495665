import React from 'react';
import useLogicDrivers from '../../hooks/useLogicDrivers';
import SelectDate from '../filter/SelectDate';
import ItemInfo from './ItemInfo';
import useBarChart from '../../hooks/useBarChart';
import { useTranslation } from 'react-i18next';

export default function DriverAnalysis({ id }) {
  const { t } = useTranslation();

  const { date, selectedOption, onChangeDate, setSelectedOption, data } = useLogicDrivers(id);
  const valueTotalHours = data?.total?.toFixed(2) || '0.00';
  const valueAverageHours = data?.average?.toFixed(2) || '0.00';
  const CHART_BAR_ID = 'chart-bars';

  useBarChart({ elementId: CHART_BAR_ID, data: data?.dataChart });

  return (
    <div className="w-full 2xl:w-3/4 max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:flex-none z-0">
      <div className="p-6 h-full border-black/12.5 shadow-soft-xl relative z-20 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
        <div className="w-full flex flex-row justify-between px-4">
          <h6 className="text-4xl font-bold ">{t('driver_overview')}</h6>
          <SelectDate
            date={date}
            onChangeDate={onChangeDate}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="flex-auto pt-4 mt-4">
          <div className="pt-4 pr-1 mb-4 bg-[#192541] rounded-xl">
            <div>
              <canvas id={CHART_BAR_ID} height="400vh" width="1200vw"></canvas>
            </div>
          </div>
          <div className="w-full px-6 mx-auto max-w-screen-2xl rounded-xl mt-8">
            <div className="flex flex-wrap mt-4 -mx-3">
              <ItemInfo label={t('total_hours')} value={valueTotalHours} />
              <ItemInfo label={t('average_hours')} value={valueAverageHours} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
