import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

export const useFilterSelect = () => {
  const { t } = useTranslation();

  const sendMail = [
    {
      value: false,
      label: t('false'),
    },
    {
      value: true,
      label: t('true'),
    },
  ];

  const type = [
    {
      value: 'EXPORT_ORDER',
      label: t('EXPORT_ORDER'),
    },
    {
      value: 'EXPORT_STC',
      label: t('EXPORT_STC'),
    },
    {
      value: 'EXPORT_DRIVER',
      label: t('EXPORT_DRIVER'),
    },
  ];

  const fromApplication = [
    {
      value: 'LOCATE_GO_PORTAL',
      label: t('LOCATE_GO_PORTAL'),
    },
    {
      value: 'SUPPLIER_PORTAL',
      label: t('SUPPLIER_PORTAL'),
    },
  ];

  return { type, sendMail, fromApplication };
};

export default function useColumns() {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('url'),
        selector: (row) =>
          row?.url ? (
            <a className="text-blue-700 hover:text-blue-400" href={row?.url}>
              <p>{row?.url}</p>
            </a>
          ) : (
            '-'
          ),
      },
      {
        name: t('fileName'),
        selector: (row) => row?.fileName ?? '-',
      },
      {
        name: t('sentMail'),
        selector: (row) => (row?.sentMail ? 'True' : 'False'),
        sortable: true,
      },
      {
        name: t('from_application'),
        selector: (row) => row?.fromApplication ?? '-',
        sortable: true,
      },
      {
        name: t('company'),
        selector: (row) => row?.companyName ?? '-',
        sortable: true,
      },

      {
        name: t('type'),
        selector: (row) => row?.type ?? '-',
      },

      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.ymdhms,
          }),
        sortable: true,
        width: '300px',
        wrap: true,
        style: {
          padding: 0,
        },
      },
    ],
    [currentTimezone?.timezone, t]
  );
  return { columns };
}
