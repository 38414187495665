import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../config/Config';

const useFilterOptionOrder = () => {
  const { t } = useTranslation();

  const [filterDelivery, setFilterDelivery] = useState([]);

  const getOpsList = async () => {
    const { data } = await axios.get(config.API_URL.DELIVERY_COMPANY.LIST);
    const dataFilterDelivery = data?.data.map((item) => {
      return { value: item?._id, label: item?.name };
    });

    setFilterDelivery(dataFilterDelivery);
  };
  useEffect(() => {
    getOpsList();
  }, []);

  const filterPayment = [
    {
      value: 'online',
      label: t('online'),
    },
    {
      value: 'Cash',
      label: t('cash'),
    },
    {
      value: 'Point',
      label: t('point'),
    },
  ];

  const filterExpress = [
    {
      value: true,
      label: t('yes'),
    },
    {
      value: false,
      label: t('no'),
    },
    {
      value: '',
      label: t('all'),
    },
  ];

  const filterIsDiscount = [
    {
      value: true,
      label: t('yes'),
    },
    {
      value: false,
      label: t('no'),
    },
    {
      value: '',
      label: t('all'),
    },
  ];

  const filterStatus = [
    {
      value: 'cancelled',
      label: t('cancelled'),
    },
    {
      value: 'deliveried',
      label: t('deliveried'),
    },
    {
      value: 'assigned',
      label: t('assigned'),
    },
    {
      value: 'new',
      label: t('new'),
    },
    {
      value: 'arrived_store',
      label: t('arrived_store'),
    },
    {
      value: 'take_order',
      label: t('take_order'),
    },
    {
      value: 'wait_customer',
      label: t('wait_customer'),
    },
  ];

  const filterCompany = [
    {
      value: 'maeda',
      label: t('Maeda'),
    },
    {
      value: 'shukah',
      label: t('Shukah'),
    },
    {
      value: 'locate',
      label: t('Locate'),
    },
    {
      value: 'locate2',
      label: t('Locate2'),
    },
  ];

  const filterGeidea = [
    {
      value: true,
      label: t('yes'),
    },
    {
      value: false,
      label: t('no'),
    },
    {
      value: '',
      label: t('all'),
    },
  ];

  const filterIsCasper = [
    {
      value: true,
      label: t('true'),
    },
    {
      value: false,
      label: t('false'),
    },
    {
      value: '',
      label: t('all'),
    },
  ];

  const filterIsMissingOrder = [
    {
      value: true,
      label: t('true'),
    },
    {
      value: false,
      label: t('false'),
    },
  ];

  return {
    filterPayment,
    filterExpress,
    filterIsDiscount,
    filterStatus,
    filterCompany,
    filterDelivery,
    filterGeidea,
    filterIsCasper,
    filterIsMissingOrder,
  };
};
export default useFilterOptionOrder;
