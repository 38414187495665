import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
const { useMemo } = require('react');

const useColumn = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('name'),
        selector: 'name',
        sortable: true,
      },
      {
        name: t('cell_phone'),
        selector: 'phone',
      },
      // {
      //   name: t('company'),
      //   selector: 'company_name',
      //   sortable: true,
      // },
      {
        name: t('subscription_name'),
        selector: ({ subscriptions = [] }) => {
          if (isEmpty(subscriptions)) return '--';
          const { subscription_info = {} } = subscriptions[0];
          const { subscriptionName } = subscription_info;
          return <div>{subscriptionName || '--'}</div>;
        },
        sortable: true,
      },
      {
        name: 'حالة اشتراك السائق',
        selector: ({ subscriptions = [] }) => {
          if (isEmpty(subscriptions)) return '--';

          const { membership_status } = subscriptions[0];
          return (
            <div
              className={`${
                membership_status === 'ACTIVE' ? 'text-green-600' : 'text-gray-300'
              } font-semibold `}
            >
              {membership_status || '--'}
            </div>
          );
        },
        sortable: true,
      },
      {
        name: t('subscription_price'),
        selector: ({ subscriptions = [] }) => {
          if (isEmpty(subscriptions)) return '--';

          const { price } = subscriptions[0];
          return <div>{price || '0.00'}</div>;
        },
        sortable: true,
      },
      {
        name: 'تاريخ انتهاء الصلاحية',
        selector: ({ subscriptions = [] }) => {
          if (isEmpty(subscriptions)) return '--';

          const { due_date = {} } = subscriptions[0];

          return (
            <div>
              {due_date
                ? parseDateWithGmt({
                    date: due_date,
                    timezone: currentTimezone?.timezone,
                  })
                : '--'}
            </div>
          );
        },
        sortable: true,
      },
      {
        name: t('subscription_description'),
        selector: ({ subscriptions = [] }) => {
          if (isEmpty(subscriptions)) return '--';

          const { subscription_info = {} } = subscriptions[0];
          const { description } = subscription_info;

          return <div>{description || '--'}</div>;
        },
        sortable: true,
        maxWidth: '350px',
      },
    ],
    [t, currentTimezone]
  );
  return { columns };
};

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 12,
      background: '#F9F9F9',
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      fontSize: 12,
    },
  },
  header: {
    style: {
      minHeight: 0,
    },
  },
  subHeader: {
    style: {
      minHeight: 0,
    },
  },
};

export { customStyles, useColumn };
