import React from 'react';
import ReactPaginate from 'react-paginate';
import { IconTablePaginateNext, IconTablePaginatePrev } from '../../constants/Icon';

function Pagination({ page, pages, onClick }) {
  const arrowLeft = () => (
    <div className="flex h-12 w-12 items-center justify-center rounded-md border">
      <IconTablePaginateNext />
    </div>
  );

  const arrowRight = () => (
    <div className="flex h-12 w-12 items-center justify-center rounded-md border">
      <IconTablePaginatePrev />
    </div>
  );

  if (pages <= 1) return null;

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={arrowRight()}
      pageRangeDisplayed={5}
      pageCount={pages}
      previousLabel={arrowLeft()}
      renderOnZeroPageCount={null}
      forcePage={page - 1}
      onPageChange={({ selected }) => onClick(selected)}
      className="flex max-w-full flex-wrap items-center justify-center [&>li]:my-1 md:[&>li]:my-0"
      pageLinkClassName="w-12 h-12 mx-1 border rounded-md block flex justify-center items-center transition duration-300"
      activeLinkClassName="bg-gray-300 font-bold"
    />
  );
}

export default Pagination;
