import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../helper/axios/axiosInstance';
import { config } from '../../config/Config';
import { debounce } from 'lodash';

const DeleteSubscription = ({ visible, toggleModal, defaultSubscription, getDataSubscription }) => {
  const { t } = useTranslation();

  function closeModal() {
    toggleModal(false);
  }

  const handleDelete = async () => {
    const idSubscription = defaultSubscription?._id;
    try {
      await axiosInstance.delete(`${config.API_URL.SUBSCRIPTION.GET_LIST}/${idSubscription}`, {
        headers: config.headers,
      });
      toast(t('delete_success'));
      getDataSubscription();
      toggleModal(false);
    } catch (error) {
      toast.error(t(`delete_fail`));
    }
  };

  const debounceDelete = debounce(handleDelete, 1000);

  const renderAdd = () => {
    return (
      <div className="inline-block w-[700px] overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="my-10 pl-6 text-right text-3xl flex flex-col gap-y-10 transition-all">
          {t('delete_subscription')}
        </div>
        <div className="flex gap-3">
          <button
            onClick={debounceDelete}
            type="button"
            className="ml-12 font-medium rounded-lg text-xl px-12 py-2.5 h-[35px] justify-center bg-red-500 hover:bg-red-700 focus:ring-1 items-center flex text-white"
          >
            {t('delete')}
          </button>
          <button
            onClick={closeModal}
            type="button"
            className="ml-12 font-medium rounded-lg text-xl px-12 py-2.5 h-[35px] hover:bg-gray-300 justify-center items-center flex border"
          >
            {t('close')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderAdd()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteSubscription;
