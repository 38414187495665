import { useCallback, useState } from 'react';

import DescriptionRating from './DescriptionRating';
import DriverRating from './DriverRating';
import StoreRating from './StoreRating';
import { useTranslation } from 'react-i18next';

const RatingTab = () => {
  const { t } = useTranslation();

  const TypeTab = {
    driverRatingStore: t('driverRatingStore'),
    driverRating: t('driverRating'),
    all: t('all'),
  };

  const [filters, setFilters] = useState({});
  const [meta, setMeta] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [star, setStar] = useState({ min: 0, max: 5 });
  const [loading, setLoading] = useState(true);

  const [typeTab, setTypeTab] = useState(TypeTab.all);

  const handleTab = (type) => {
    setTypeTab(type);
    setFilters({});
    setStar({ min: 0, max: 5 });
    setCurrentPage(1);
  };

  const renderTab = useCallback(
    () => (
      <>
        <button
          className={`tab-btn ${typeTab === TypeTab.driverRatingStore ? 'active-tab' : ''}`}
          onClick={() => handleTab(TypeTab.driverRatingStore)}
        >
          {TypeTab.driverRatingStore}
        </button>
        <button
          className={`tab-btn ${typeTab === TypeTab.driverRating ? 'active-tab' : ''}`}
          onClick={() => handleTab(TypeTab.driverRating)}
        >
          {TypeTab.driverRating}
        </button>
        <button
          className={`tab-btn ${typeTab === TypeTab.all ? 'active-tab' : ''}`}
          onClick={() => handleTab(TypeTab.all)}
        >
          {TypeTab.all}
        </button>
      </>
    ),
    [typeTab]
  );

  const renderTabTableRating = () => {
    if (typeTab === TypeTab.driverRatingStore) {
      return (
        <StoreRating
          loading={loading}
          setLoading={setLoading}
          type={typeTab}
          setTypeTab={setTypeTab}
          filters={filters}
          setFilters={setFilters}
          meta={meta}
          setMeta={setMeta}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          star={star}
          setStar={setStar}
        />
      );
    }
    if (typeTab === TypeTab.driverRating) {
      return (
        <DriverRating
          loading={loading}
          setLoading={setLoading}
          type={typeTab}
          setTypeTab={setTypeTab}
          filters={filters}
          setFilters={setFilters}
          meta={meta}
          setMeta={setMeta}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          star={star}
          setStar={setStar}
        />
      );
    }
    if (typeTab === TypeTab.all) {
      return (
        <DescriptionRating
          loading={loading}
          setLoading={setLoading}
          type={typeTab}
          setTypeTab={setTypeTab}
          filters={filters}
          setFilters={setFilters}
          meta={meta}
          setMeta={setMeta}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          limit={limit}
          setLimit={setLimit}
          star={star}
          setStar={setStar}
        />
      );
    }
  };

  return (
    <div className="">
      <div className="action-list justify-end">{renderTab()}</div>

      {renderTabTableRating()}
    </div>
  );
};

export default RatingTab;
