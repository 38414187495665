/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../context/DataContext';

toast.configure();

const ToggleMaeda = memo(() => {
  const { t } = useTranslation();
  const { isCurrentDeliveryStatus, handleChangeCurrentDeliveryStatus } = useContext(DataContext);

  return (
    <>
      <div className="setting">
        <h3>{t('Enable express')}:</h3>
        <div className="row flex items-center gap-x-4">
          <label className="switch">
            <input
              checked={isCurrentDeliveryStatus}
              type="checkbox"
              name="isEnable"
              onChange={handleChangeCurrentDeliveryStatus}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
    </>
  );
});

export default ToggleMaeda;
