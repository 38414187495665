import moment from 'moment';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useMapTracking from '../../../../hooks/useMapTracking';
import { toast } from 'react-toastify';

const OrderCard = memo(({ order, handlerAssign }) => {
  const { t } = useTranslation();
  const {
    _id,
    order_number,
    restaurant_name,
    driver_id,
    customer_name,
    customer_city,
    customer_address,
    created_at,
    restaurant_phone,
    customer_phone,
  } = order;
  const driver_name = order?.driver?.name ?? '';

  const { handlerSelectedDriver, setOrderFocus, filtersOrder, onChangeFieldFilterOrder } =
    useMapTracking();

  const handleSelectOrder = () => {
    setOrderFocus(order);
    if (order?.driver) {
      handlerSelectedDriver({ driver: order?.driver });
    }
    if (order?.driver?.id && !filtersOrder.orderAssigned) {
      onChangeFieldFilterOrder('orderAssigned', true);
    }
    if (!order?.driver?.id && filtersOrder.orderAssigned) {
      onChangeFieldFilterOrder('orderAssigned', false);
    }
  };

  const assignOrder = (event) => {
    if (typeof handlerAssign === 'function') {
      handlerAssign(event, order);
    }
  };

  const handleCopyInfoOrder = () => {
    const textarea = document.createElement('textarea');
    textarea.value = `
    - رقم الطلب: ${order_number}
    - اسم المطعم: ${restaurant_name}
    - رقم المطعم: ${restaurant_phone}
    - اسم العميل: ${customer_name}
    - رقم هاتف العميل: ${customer_phone}
    - مدينة العميل: ${customer_address}`;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand('copy');

    toast(t('Information order copied to clipboard'), {
      position: toast.POSITION.TOP_LEFT_LEFT,
    });

    document.body.removeChild(textarea);
  };

  return (
    <>
      <li key={_id}>
        <div className="content">
          <div className="order-info" onClick={handleSelectOrder}>
            <div className="order-information">
              <h1>{order_number}</h1>
              <span className="restaurant">{restaurant_name}</span>
              <span className={`order-status ${!driver_id ? 'new' : 'inprogress'}`}>
                {!driver_id ? t('new') : t('in_progress')}
              </span>
            </div>
            <div className="info-customer">
              <h3>{customer_name}</h3>
              <span>
                {customer_address}, {customer_city}
              </span>
              <span>{driver_name}</span>
              <span>{moment(created_at).format('LLL')}</span>
            </div>
          </div>
          <div className="flex justify-end py-1">
            {/* {!driver_id && ( */}
            <button className="rounded-full bg-[#b3b3b3] p-2" onClick={handleCopyInfoOrder}>
              <img
                className="rounded-md w-8 h-8 bg-gray-100"
                src="/images/copy.png"
                alt="copyImage"
              />
            </button>
            {/* )} */}
            <a
              href={`/orders/${order_number}`}
              className="button to-invoice"
              target="_blank"
              title="Infomation invoice"
              without="true"
              rel="noreferrer"
            >
              <svg
                viewBox="0 0 24 24"
                width="16"
                height="16"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </a>
            <button className="button assign-button" onClick={assignOrder}>
              <svg
                viewBox="0 0 24 24"
                width="16"
                height="16"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <line x1="20" y1="8" x2="20" y2="14"></line>
                <line x1="23" y1="11" x2="17" y2="11"></line>
              </svg>
            </button>
          </div>
        </div>
      </li>
    </>
  );
});

export default OrderCard;
