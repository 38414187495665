import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import useSchema from './useSchema';
import GenerateTestResults from './GenerateTestResults';

export default function TestResults({ valueOdmWeightAge }) {
  const { schema, customStyles } = useSchema();
  const [openGenerateTestResults, setOpenGenerateTestResults] = useState(false);
  const [testResults, setTestResults] = useState([]);

  const recordTest = (test) => {
    setTestResults([test, ...testResults]);
  };

  return (
    <>
      <div className="flex justify-between items-center p-4">
        <p className="text-2xl font-bold">Test formula</p>
        <button
          onClick={() => setOpenGenerateTestResults(true)}
          className="h-[35px] border bg-gray-100 hover:bg-gray-300 font-medium rounded-lg text-xl px-3 py-2"
        >
          Generate test results
        </button>
      </div>
      <DataTable
        data={testResults}
        columns={schema}
        noDataComponent={<div style={{ padding: '24px' }}>No data</div>}
        customStyles={customStyles}
        persistTableHead
      />
      <GenerateTestResults
        recordTest={recordTest}
        valueOdmWeightAge={valueOdmWeightAge}
        isOpen={openGenerateTestResults}
        setIsOpen={setOpenGenerateTestResults}
      />
    </>
  );
}
