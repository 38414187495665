import React, { useEffect, useState } from 'react';
import DistributionFormulaForm from './DistributionFormulaForm';
import { axiosGet } from '../../helper/axios/axiosGet';
import { config } from '../../config/Config';
import TestResults from './TestResults';

export default function OrdersDistributionMechanism() {
  const [valueOdmWeightAge, setValueOdmWeightAge] = useState();
  const getDetailLog = async () => {
    try {
      const { data } = await axiosGet(config.API_URL.SETTING.ODM, {
        headers: config.headers,
      });

      setValueOdmWeightAge(data.data);
    } catch (error) {
      console.log('🚀 ~ getDetailLog ~ error:', error);
    }
  };

  useEffect(() => {
    getDetailLog();
  }, []);

  return (
    <article className="p-6 rtl">
      <section>
        <h2 className="text-4xl mb-2 font-bold">Order distribution mechanism</h2>
        <p className="text-2xl mb-5">
          Manage variation of this mechanism using availability, distance and idle time
        </p>
      </section>
      <section className="rounded-lg border mb-5">
        <DistributionFormulaForm valueOdmWeightAge={valueOdmWeightAge} />
      </section>
      <section className="rounded-lg border">
        <TestResults valueOdmWeightAge={valueOdmWeightAge} />
      </section>
    </article>
  );
}
