import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../commonComponents/LoadingButton';
import { config } from '../../config/Config';
import useEventEnter from '../../hooks/useEventEnter';
import CustomSelectReact from '../CustomSelectReact';

import { customStyles } from '../Rating/useColumn';
import useColumns, { useFilterSelect } from './useColumns';
import { axiosGet } from '../../helper/axios/axiosGet';
import { isEmpty } from 'lodash';
import DetailLogExport from './DetailLogExport';
import { conditionalRowStyles } from '../DriverRegistration/constants';

const LogExport = () => {
  const { t } = useTranslation();
  const { columns } = useColumns();
  const tableHeight = window.innerHeight - 330;
  const { type, sendMail, fromApplication } = useFilterSelect();

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [logExport, setLogExport] = useState();
  const [dataFocus, setDataFocus] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);

  const handleChangePage = (nextPage) => {
    if (Number(nextPage) === Number(currentPage) || isLoading) return false;

    setCurrentPage(nextPage);
  };

  const handleSetFilters = (newFilter) => {
    setCurrentPage(1);
    setFilters(newFilter);
  };

  const handleChangeDateOnFilter = (value) => {
    if (!value) {
      setDateRange([null, null]);
    }

    handleSetFilters({
      ...filters,
      date: value ? [value[0], value[1]] : [null, null],
    });
  };

  useEffect(() => {
    const { date } = filters;

    if (date) {
      const dateValue = [date[0], date[1]];

      setDateRange(dateValue);
    } else {
      setDateRange([null, null]);
    }
  }, [filters]);

  const handleChangeFilter = (optionValues, type) => {
    const newFilters = {
      ...filters,
      [type]: optionValues,
    };

    handleSetFilters(newFilters);
  };

  const getDataLogExport = async () => {
    setIsLoading(true);
    const { type, sentMail, date, fromApplication, fromCompanyId } = filters;
    const dateRange = date ?? [];
    const createdAtFrom = dateRange[0] ?? '';
    const createdAtTo = dateRange[1] ?? '';
    const companyIdWithApplication =
      fromApplication?.value === 'SUPPLIER_PORTAL' ? fromCompanyId?.id : '';

    const newFilters = {
      fromApplication: fromApplication?.value ?? '',
      fromCompanyId: companyIdWithApplication ?? '',
      type: type?.value ?? '',
      sentMail: sentMail?.value ?? '',
      createdAtFrom: createdAtFrom,
      createdAtTo: createdAtTo,
      limit: limit,
      page: currentPage,
    };
    try {
      const {
        data: { total, data },
      } = await axiosGet(`${config.API_URL.LOG_EXPORT.GET_LIST}`, {
        params: {
          ...newFilters,
        },
        headers: config.headers,
      });

      setLogExport(data);
      setTotalRecords(total);
    } catch (error) {
      console.log('Error - LogExport', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyList = async () => {
    const { data } = await axiosGet(config.API_URL.DELIVERY_COMPANY.LIST);
    if (data && data?.success) {
      const terraformData = await data?.data?.map((company) => {
        return {
          label: company.name,
          value: company.name,
          id: company._id,
        };
      });
      setCompanies(terraformData);
    }
  };
  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    getDataLogExport();
  }, [currentPage, limit]);

  const handleGetDataByFilter = async () => {
    setCurrentPage(1);
    getDataLogExport();
  };

  useEventEnter(handleGetDataByFilter);

  const toggleModal = (visible) => {
    setModalVisible(visible);
    if (!isEmpty(dataFocus)) {
      setDataFocus(null);
    }
  };

  const handleToggleModal = (data) => {
    toggleModal(true);
    setDataFocus(data);
  };

  return (
    <div className="rtl">
      <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap">
        {/* <div className="flex">
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('type')}
            onChange={(value) => handleChangeFilter(value, 'type')}
            className="filter-select min-w-[200px]"
            options={type}
            value={filters.type}
          />
        </div> */}

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('sentMail')}
            className="checkbox-order w-[200px] filter-select"
            options={sendMail}
            value={filters?.sentMail}
            onChange={(value) => handleChangeFilter(value, 'sentMail')}
          />
        </div>

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={isLoading}
            placeholder={t('from_application')}
            className="checkbox-order w-[200px] filter-select"
            options={fromApplication}
            value={filters?.fromApplication}
            onChange={(value) => handleChangeFilter(value, 'fromApplication')}
          />
        </div>

        <div>
          <CustomSelectReact
            isClearable
            isDisabled={filters?.fromApplication?.value === 'SUPPLIER_PORTAL' ? false : true}
            placeholder={t('company')}
            className="checkbox-order w-[200px] filter-select"
            options={companies}
            value={filters?.fromCompanyId}
            onChange={(value) => handleChangeFilter(value, 'fromCompanyId')}
          />
        </div>

        <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
          <DateTimeRangePicker
            disabled={isLoading}
            className="input-select-input-wrapper !border-0 w-[200px]"
            value={dateRange}
            onChange={handleChangeDateOnFilter}
            disableClock
            format={'y-MM-dd'}
          />
        </div>

        <div className="relative w-full mb-4">
          <div className="absolute right-0 ">
            <LoadingButton
              onClick={handleGetDataByFilter}
              label={t('Apply Filter')}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={logExport}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={+limit}
        paginationRowsPerPageOptions={[10, 20, 40, 60]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={isLoading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={totalRecords}
        paginationServer
        onChangePage={handleChangePage}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        onRowClicked={(row) => handleToggleModal(row)}
        selectableRows
        conditionalRowStyles={conditionalRowStyles}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={customStyles}
      />
      <DetailLogExport visible={modalVisible} toggleModal={toggleModal} dataFocus={dataFocus} />
    </div>
  );
};

export default LogExport;
