import { Fragment, useMemo, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import LoadingButton from '../../../commonComponents/LoadingButton/LoadingButton';
import Loading from '../../../commonComponents/LoadingView/Loading';

import { handleMessage } from '../../../helper/utils';
import { FORMAT_DATE, parseDateWithGmt } from '../../../helper/date/parseDateGmt';

import { useDate } from '../../../hooks/useDate';

import { IconClose, PlusIcon, Subtraction } from '../../../constants/Icon';

import { config } from '../../../config/Config';
import { FROM_COMPANY_MAP } from '../../../constants/reOrder';
import { isEmpty } from 'lodash';

export default function ReOrderDetail({
  loading,
  closeModal,
  orderDataDetail,
  cart,
  onPressSubtractionToping,
  addItemToCart,
  onAddItemToCart,
  onSubtractionToCart,
  onPressAddToping,
  totalResult,
  totalQuantityPrice,
  totalItemPrice,
  missingReOrder,
  setIsModalVisible,
  isModalVisible,
  addTopingItemToCart,
  newItemOrderDataDetail,
  removeItemOnCart,
  removeToppingOnCart,
  totalReOrderIsMissing,
  setCart,
  data,
  orderRowSelected,
}) {
  const paymentMethod = orderDataDetail?.payment_method;
  const isVisibleReOrderWithOrderStatus = useMemo(() => {
    if (orderRowSelected.isCanceled || orderRowSelected?.isDelivered) {
      return true;
    }
    return false;
  }, [orderRowSelected?.isCanceled, orderRowSelected?.isDelivered]);
  const isPaymentMethodOnline = ['online', 'card'].includes(paymentMethod?.toLocaleLowerCase());
  // const paymentMethod = orderDataDetail?.payment_method;

  // const isPaymentMethodOnline = paymentMethod?.toLocaleLowerCase() === 'online';
  // const isPaymentMethodOnline = ['online', 'card'].includes(paymentMethod?.toLocaleLowerCase());

  const totalReOrder = totalQuantityPrice + totalItemPrice;
  const { t } = useTranslation();
  const { currentTimezone } = useDate();
  const [isLoading, setIsLoading] = useState(false);

  const newCart = cart.map((item) => {
    const { options } = item;
    const newOptions = options.map((option) => {
      const { addon_id, id, quantity } = option;
      return { addon_id, id, quantity };
    });
    const newOptionsWithQuantity = newOptions.filter((option) => option.quantity !== 0);
    return { ...item, options: newOptionsWithQuantity };
  });

  const isDisableReOrderBtn = () => {
    if (isLoading) {
      return true;
    }
    if (totalResult === 0) return true;
    return false;
  };
  const onPressReOrder = async () => {
    try {
      setIsLoading(true);
      const orderData = data?.find((item) => {
        return item?.order_number == orderDataDetail?.order_id;
      });
      const response = await axios.post(
        config.API_URL.ORDERS.RE_ORDER(orderDataDetail?.order_id),
        newCart,
        {
          headers: {
            ...config.headers,
            'x-from-application':
              FROM_COMPANY_MAP?.[orderData?.from_company] || FROM_COMPANY_MAP.Shukah,
          },
        }
      );
      if (response?.status === 200) {
        handleMessage('success', 'Re-order successfully');
        setIsModalVisible(!isModalVisible);
        setCart([]);
      }
    } catch (error) {
      setIsLoading(false);
      const errorList = error?.response?.data?.data;
      if (Array.isArray(errorList)) {
        errorList?.forEach((error) => {
          return handleMessage('error', error?.message);
        });
      } else {
        handleMessage('error', errorList?.message);
      }
    }
  };

  // renderMissingItemTable

  const renderMissingItemTable = () => {
    if (cart) {
      return (
        <div>
          <p className="text-3xl font-black headContainerTable">{t('missing_order_retail')}</p>
          <table
            id="dtBasicExample"
            className="table table-striped-order table-bordered "
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th className="th-sm quantity" />
                <th className="th-sm quantity">{t('quantity')}</th>
                <th className="th-sm item">{t('item')}</th>
                <th className="th-sm price">{t('price')}</th>
              </tr>
            </thead>
            <tbody>
              {cart?.map((data, index) => {
                const totalPrice = Number(data.item_price) * Number(data.order_q);
                return (
                  <Fragment key={`item-${index}`}>
                    <tr className="quantity">
                      <td>
                        <button onClick={() => removeItemOnCart(data?.idItemLocal)}>
                          <p className="font-bold text-justify text-l">{t('remove')}</p>
                        </button>
                      </td>
                      <td>
                        <div className="flex flex-row">
                          {data.order_q < data?.item_quantity && (
                            <p onClick={() => onAddItemToCart(data, index)}>
                              <PlusIcon />
                            </p>
                          )}

                          <p className="px-2 text-xl font-bold">{Number(data.order_q)}</p>
                          {data.order_q > 0 && (
                            <p onClick={() => onSubtractionToCart(data, index)}>
                              <Subtraction />
                            </p>
                          )}
                        </div>
                      </td>
                      <td className="text-2xl font-bold "> {data.item_name} </td>
                      <td>{totalPrice.toFixed(2)}</td>
                    </tr>
                    {data?.options.map((topping, indexToping) => {
                      return (
                        <tr key={indexToping} className="quantity">
                          <td className="bg-white">
                            <td>
                              <button onClick={() => removeToppingOnCart(topping?.idToppingLocal)}>
                                <p className="text-3xl font-bold mr-9 ">-</p>
                              </button>
                            </td>
                          </td>
                          <td className="bg-white ">
                            <div className="flex flex-row ">
                              {topping?.max_topping > topping.quantity && (
                                <p onClick={() => onPressAddToping(topping, index, indexToping)}>
                                  <PlusIcon />
                                </p>
                              )}
                              <p className="px-2 text-xl bg-white">{topping.quantity}</p>
                              {topping.quantity > 0 && (
                                <p
                                  onClick={() =>
                                    onPressSubtractionToping(topping, index, indexToping)
                                  }
                                >
                                  <Subtraction />
                                </p>
                              )}
                            </div>
                          </td>
                          <td className="bg-white">{topping?.name}</td>
                          <td className="bg-white">
                            {Number(topping?.price * topping.quantity).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const renderOrderDetailTable = () => {
    return (
      <div>
        <p className="text-3xl font-black headContainerTable">
          {missingReOrder !== 1 ? 'Order Detail' : 'Re-Order Detail'}
        </p>
        <table
          id="dtBasicExample"
          className="table table-striped-order table-bordered "
          cellSpacing="0"
        >
          <thead>
            <tr>
              {missingReOrder !== 1 && <th className="th-sm quantity">{t('add')}</th>}
              <th className="th-sm quantity">{t('quantity')}</th>
              <th className="th-sm item">{t('item')}</th>
              <th className="th-sm price">{t('price')}</th>
            </tr>
          </thead>
          <tbody>
            {newItemOrderDataDetail?.map((data, index) => {
              const totalPrice = Number(data.item_quantity) * Number(data.item_price);
              return (
                <Fragment key={`itemOrder-${index}`}>
                  <tr className="quantity">
                    {missingReOrder !== 1 && (
                      <td>
                        <button
                          onClick={() => {
                            addItemToCart(data, index);
                          }}
                        >
                          <p className="mr-6 text-2xl font-bold text-justify">+</p>
                        </button>
                      </td>
                    )}

                    <td className="text-2xl font-bold ">{data.item_quantity}</td>
                    <td className="text-2xl font-bold"> {data.item_name} </td>
                    <td className="text-2xl font-bold">{totalPrice.toFixed(2)}</td>
                  </tr>
                  {data?.options?.map((topping, indexToping) => {
                    return (
                      <tr key={`toping-${indexToping}`} className="quantity">
                        {missingReOrder !== 1 && (
                          <td className="bg-white">
                            <button
                              onClick={() => {
                                addTopingItemToCart(data, topping);
                              }}
                            >
                              <p className="mr-6 text-2xl font-bold text-justify">+</p>
                            </button>
                          </td>
                        )}

                        <td className="bg-white ">
                          <p className="text-xl bg-white"> {topping.quantity}</p>
                        </td>
                        <td className="bg-white ">{topping?.name}</td>
                        <td className="bg-white ">
                          {Number(topping?.price * topping.quantity).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDataOrderDetail = () => {
    return (
      <div className="inline-block w-full h-full px-10 py-12 my-8 overflow-x-hidden overflow-y-hidden text-left align-middle transition-all duration-300 transform bg-white shadow-xl max-w-7xl rounded-2xl">
        <div className="flex items-center gap-x-4">
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
          <span className="text-3xl font-bold">ID Order :</span>
          <span className="text-3xl leading-6">{orderDataDetail?.order_id}</span>
        </div>
        <div className="headContainer rtl">
          <div className="rowRight">
            <div className="orderDetailHeadRow">
              <label>{t('order_number')}</label>
              <span className="ltr">{orderDataDetail?.order_number}</span>
            </div>

            <div className="orderDetailHeadRow">
              <label>{t('restaurant_number')} </label>
              <span>{orderDataDetail?.restaurant_phone}</span>
            </div>
            <div className="orderDetailHeadRow">
              <label>{t('restaurant_city')} </label>
              <span>{orderDataDetail?.restaurant_city}</span>
            </div>
            <div className="orderDetailHeadRow">
              <label>{t('restaurant_name')} </label>
              <span>{orderDataDetail?.restaurant_name}</span>
            </div>
          </div>
          <div className="rowLeft">
            <div className="orderDetailHeadRow">
              <label>{t('customer')}</label>
              <span>{orderDataDetail?.customer_name}</span>
            </div>
            <div className="orderDetailHeadRow">
              <label>{t('customer_number')} </label>
              <span>{orderDataDetail?.customer_phone}</span>
            </div>
            <div className="orderDetailHeadRow">
              <label>{t('ordered_at')} </label>
              <span>
                {parseDateWithGmt({
                  date: orderDataDetail?.created_at,
                  timezone: currentTimezone?.timezone,
                  format: FORMAT_DATE.ymdhms,
                })}
              </span>
            </div>
            <div className="orderDetailHeadRow">
              <label>{t('customer_city')} </label>
              <span>{orderDataDetail?.customer_city}</span>
            </div>
            <div className="orderDetailHeadRow">
              <label>{t('customer_address')} </label>
              <span>{orderDataDetail?.customer_address}</span>
            </div>
          </div>
        </div>
        {renderOrderDetailTable()}

        {missingReOrder !== 1 && renderMissingItemTable()}

        <p className="text-3xl headContainerTable">
          ﷼ Total:{' '}
          {missingReOrder !== 1 ? totalReOrder?.toFixed(2) : totalReOrderIsMissing?.toFixed(2)}
        </p>

        {missingReOrder !== 1 && isEmpty(orderDataDetail?.parent_order_id) && (
          <LoadingButton
            loading={isLoading}
            label="Re-Order"
            onClick={isDisableReOrderBtn() ? null : onPressReOrder}
            disable={isDisableReOrderBtn()}
          />
        )}
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return <div>{renderDataOrderDetail()}</div>;
}
