import { Dialog, Listbox, Transition } from '@headlessui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { config } from '../../../config/Config';
import { CheckMarkOutLine, Chevron, IconClose, IconSpin } from '../../../constants/Icon';
import { handleMessage } from '../../../helper/utils';

const optionFilter = [
  { id: 1, name: 'Filter' },
  { id: 2, name: 'Global' },
];
function NotificationModal({
  visible,
  toggleModal,
  getFilterObject,
  sendNotificationListDriver,
  driverSelect,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendNotificationBy, setSendNotificationBy] = useState(optionFilter[1]);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      isGlobal: true,
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required(t('send_noti.validate.required.title')),
      message: Yup.string().trim().required(t('send_noti.validate.required.message')),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  function closeModal() {
    setIsOpen(false);
    toggleModal(false);
    formik.resetForm();
    formik.setErrors({});
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (visible) {
      openModal();
    }
  }, [visible]);

  const handleSubmit = async () => {
    setIsLoading(true);
    let finalizeData = {
      title: formik.values.title,
      message: formik.values.message,
    };
    if (sendNotificationListDriver) {
      const listDriverId = [];
      driverSelect?.map((item) => listDriverId.push(item._id));
      console.log('listDriverId', listDriverId);
      finalizeData = { ...finalizeData, drivers: listDriverId };
    } else {
      if (sendNotificationBy.name === 'Filter') {
        console.log('xxxx', getFilterObject());
        finalizeData = {
          ...finalizeData,
          filters: getFilterObject(),
        };
      } else {
        finalizeData = { ...finalizeData, isGlobal: formik.values.isGlobal };
      }
    }

    try {
      const { data } = await axios.post(
        sendNotificationBy.name === 'Global'
          ? config.API_URL.NOTIFICATION.MESSAGE
          : config.API_URL.NOTIFICATION.MESSAGE_DRIVER,
        finalizeData,
        {
          headers: config.headers,
        }
      );
      if (data?.success) {
        handleMessage('success', t('send_noti.toast_mess.successed'));
        closeModal();
      } else {
        handleMessage('error', t('send_noti.toast_mess.failed'));
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Axios error', error);
      handleMessage('error', t('send_noti.toast_mess.failed'));
      setIsLoading(false);
    }
  };

  const renderNotiModal = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-5xl h-[calc(100vh-50vh)] overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-right align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading_data')}</p>
          </div>
        </div>
      );
    }
    return (
      <form
        onSubmit={formik.handleSubmit}
        className="inline-block w-full max-w-5xl overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-right align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl"
      >
        <div className="flex items-center justify-between gap-x-4">
          <h3 className="text-3xl font-bold"> {t('send_noti.notification')} </h3>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>

        <div className="flex flex-col ">
          <div className="my-10 text-right flex flex-col gap-y-10 overflow-y-auto transition-all">
            <div className="gap-x-4">
              {!sendNotificationListDriver && (
                <div className="flex justify-between pl-0.5 mb-4">
                  <p className="font-bold py-6">Send Notification by</p>
                  <div className="w-9/12">
                    <div className="w-72">
                      <Listbox value={sendNotificationBy} onChange={setSendNotificationBy}>
                        <div className="relative mt-1">
                          <Listbox.Button className="flex justify-between items-center w-full cursor-default rounded-lg bg-white py-1 pl-3 pr-10 text-left shadow-md border border-gray-100 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm ">
                            <div className="block truncate ">{sendNotificationBy.name}</div>
                            <div className="pointer-events-none  inset-y-0   pr-2 w-10 h-10 flex items-center justify-center">
                              <Chevron color="grey" aria-hidden="true" />
                            </div>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {optionFilter.map((person, personIdx) => (
                                <Listbox.Option
                                  key={personIdx}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                    } py-4 `
                                  }
                                  value={person}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected ? 'font-medium' : 'font-normal'
                                        } `}
                                      >
                                        {person.name}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600 w-12 h-12">
                                          <CheckMarkOutLine color="#3BCE63" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-between pl-0.5 mb-4">
                <p className="font-bold py-6"> {t('send_noti.title')} </p>
                <div className="w-9/12">
                  <input
                    type="text"
                    // required
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    className="rounded-lg my-2 p-4 w-full border border-slate-300 text-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                  />
                  {formik.errors.title && formik.touched.title && (
                    <p className="flex justify-end text-red-500 pt-2 pb-6 text-xl font-bold float-right">
                      {formik.errors.title}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-between pl-0.5">
                <p className="font-bold py-6"> {t('send_noti.message')} </p>
                <div className="w-9/12">
                  <textarea
                    className="rounded-lg my-2 p-4 w-full border border-slate-300 text-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 resize-none text-justify"
                    cols="30"
                    rows="5"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.errors.message && formik.touched.message && (
                    <p className="flex justify-end text-red-500 pt-2 pb-6 text-xl font-bold float-right">
                      {formik.errors.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center w-full">
            <button className="btn-primary rounded-md px-3 py-4 " type="submit">
              {t('send_noti.submit')}
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center rtl">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {renderNotiModal()}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default NotificationModal;
