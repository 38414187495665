import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { getProfile } from '../api/profile';
import { config, SETTING_KEY } from '../config/Config';
import { getSettingTimezone } from '../hooks/useDate';
import { Slide, toast } from 'react-toastify';
import { handleMessage } from '../helper/utils';

export const DataContext = createContext();
const DataContextProvider = (props) => {
  const [data, setData] = useState([]);
  const [profileAdmin, setProfileAdmin] = useState(null);
  const [currentTimezone, setCurrentTimezone] = useState();
  const [isOpenFetchDataWhenZoom, setOpenFetchDataWhenZoom] = useState(false);
  const [isLoadingFetchDataWhenZoom, setIsLoadingFetchDataWhenZoom] = useState(false);

  const [isCurrentDeliveryStatus, setIsCurrentDeliveryStatus] = useState(true);
  const onGetProfileAdmin = async () => {
    const data = await getProfile();
    setProfileAdmin(data);
  };

  const onGetSettingTimeZone = async () => {
    const settingTimezone = await getSettingTimezone();

    setCurrentTimezone(settingTimezone);
  };
  useEffect(() => {
    onGetSettingTimeZone();
  }, []);

  useEffect(() => {
    onGetProfileAdmin();
  }, []);

  useEffect(() => {
    getSettingMap();
  }, []);

  const getCurrentDeliveryStatus = async () => {
    try {
      const resp = await axios.get(
        config.API_URL.ORDERS.GET_CURRENT_DELIVERY_STATUS,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      if (resp?.data?.status === 200) {
        setIsCurrentDeliveryStatus(resp?.data?.sendOrdersToLocate)
      }
    } catch (error) {
      console.log('🚀 ========= error:', error)
    }
  };

  useEffect(() => {
    getCurrentDeliveryStatus();
  }, []);

  const sendToSlack = async (status) => {
    const message = {
      text: `Express delivery in by app have ${!status ? 'closed' : 'opened'}`,
      type: 'mrkdwn',
      attachments: [
        {
          color: '#E01E5A',
          fields: [
            {
              title: 'Environment',
              value: 'Production',
              short: true,
            },
            {
              title: 'Driver',
              value: 'ALL DRIVER',
              short: true,
            },
          ],
        },
      ],
    };
    handleMessage('success', `Express delivery in by app have ${!status ? 'closed' : 'opened'}`)
    await axios.post(
      `${config.API_URL.DRIVERS.SEND_MESSAGE}sendToSlack`,
      {
        message,
      },
      {
        headers: config.headers,
      }
    );
  };

  const handleChangeCurrentDeliveryStatus = async () => {
    try {
      await axios.patch(
        config.API_URL.ORDERS.CHANGE_CURRENT_DELIVERY_STATUS,
        {
          sendOrdersToLocate: !isCurrentDeliveryStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );
      setIsCurrentDeliveryStatus(!isCurrentDeliveryStatus);
      sendToSlack(!isCurrentDeliveryStatus);

    } catch (error) {
      console.log('🚀 ========= error:', error)
    }
  };

  function storeData(i) {
    setData(i);
  }

  const getSettingMap = useCallback(async () => {
    const resp = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.TOGGLE_ZOOM_MAP,
      },
      headers: config.headers,
    });
    const { data } = resp;
    if (data) {
      setOpenFetchDataWhenZoom(data?.setting?.isActive);
    }
  }, []);

  const onToggleSyncDataFilterMap = async () => {
    try {
      if (isLoadingFetchDataWhenZoom) return;
      setIsLoadingFetchDataWhenZoom(true);
      await axios.post(
        config.API_URL.SETTING.CREATE,
        {
          data: [
            {
              key: SETTING_KEY.TOGGLE_ZOOM_MAP,
              content: {
                isActive: !isOpenFetchDataWhenZoom,
              },
            },
          ],
        },
        {
          headers: config.headers,
        }
      );
      await getSettingMap();
      setIsLoadingFetchDataWhenZoom(false);
    } catch (error) {
      setIsLoadingFetchDataWhenZoom(false);
    } finally {
    }
  };

  useEffect(() => {
    getSettingMap();
  }, []);

  return (
    <DataContext.Provider
      value={{
        data,
        storeData,
        profileAdmin,
        currentTimezone,
        onGetSettingTimeZone,
        isOpenFetchDataWhenZoom,
        onToggleSyncDataFilterMap,
        isLoadingFetchDataWhenZoom,
        isCurrentDeliveryStatus,
        handleChangeCurrentDeliveryStatus
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
