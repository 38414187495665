import React, { useEffect, useState } from 'react';
import { DRIVER_HISTORY_TYPE, VERSION_HISTORY } from '../../../../constants/constants';
import { FORMAT_DATE, parseDateWithGmt } from '../../../../helper/date/parseDateGmt';
import { useDate } from '../../../../hooks/useDate';
import { config } from '../../../../config/Config';
import axios from 'axios';
import Pagination from '../../../../commonComponents/Pagination';
import { useTranslation } from 'react-i18next';

const renderNameKeyUpdateDriver = (key, t) => {
  switch (key) {
    case VERSION_HISTORY.NAME:
      return t('driver_name');
    case VERSION_HISTORY.FIRST_NAME:
      return t('firstName');
    case VERSION_HISTORY.SECOND_NAME:
      return t('secondName');
    case VERSION_HISTORY.THIRD_NAME:
      return t('thirdName');
    case VERSION_HISTORY.LAST_NAME:
      return t('lastName');
    case VERSION_HISTORY.PHONE:
      return t('phone');
    case VERSION_HISTORY.DATE_OF_BIRTH:
      return t('birth_day');
    case VERSION_HISTORY.IDENTITY_TYPE:
      return t('id_number');
    case VERSION_HISTORY.ID_NUMBER:
      return t('national_id');
    case VERSION_HISTORY.COMPANY_NAME:
      return t('company');
    case VERSION_HISTORY.STC_PAY_NUMBER:
      return t('stc_pay_number');
    case VERSION_HISTORY.CAR_TYPE:
      return t('type_of_the_vehicle');
    case VERSION_HISTORY.CAR_NUMBER:
      return t('car_number');
    case VERSION_HISTORY.REGION:
      return t('region');
    case VERSION_HISTORY.CITY:
      return t('city');
    case VERSION_HISTORY.VEHICLE_SEQUENCE_NUMBER:
      return t('vehicle_sequence_number');
    case VERSION_HISTORY.MEDICAL_INSURANCE_DATE:
      return t('medical_insurance_date');
    case VERSION_HISTORY.IS_CASPER:
      return t('isCasper');
    case VERSION_HISTORY.ATTACHMENT:
      return 'Attached documents';
    case VERSION_HISTORY.DRIVER_IMAGE:
      return 'Profile image';
    default:
      break;
  }
};

const convertContent = (key, content) => {
  if (key === VERSION_HISTORY.DRIVER_IMAGE) {
    return (
      <div className="w-16">
        <img
          src={content}
          alt="driver_image"
          className="aspect-square w-full rounded-full object-cover"
        />
      </div>
    );
  }

  if (key === VERSION_HISTORY.ATTACHMENT) {
    return (
      <div className="flex flex-wrap gap-3">
        {content.map((value, index) => (
          <div key={index} className="w-16">
            <img
              src={value?.Location ?? value}
              alt="attachment"
              className="aspect-square w-24 rounded object-cover"
            />
          </div>
        ))}
      </div>
    );
  }

  if (key === VERSION_HISTORY.IS_CASPER) return content ? 'true' : 'false';

  return content ?? '-';
};

const renderHistory = (values, t) =>
  values?.map((value) => {
    const { currentTimezone } = useDate();

    const removeKeyId = value?.update_content?.filter(
      (content) =>
        content.key !== VERSION_HISTORY.CAR_TYPE_ID &&
        content.key !== VERSION_HISTORY.IDENTITY_TYPE_ID &&
        content.key !== VERSION_HISTORY.REGION_ID &&
        content.key !== VERSION_HISTORY.CITY_ID &&
        content.key !== VERSION_HISTORY.FROM_COMPANY
    );

    return (
      <div key={value._id} className="mt-4 rounded-lg border border-greyish-200 p-3 md:p-6">
        <div className="border-b pb-4">
          <div className="flex items-center gap-6">
            <p className="w-fit text-2xl font-bold">Update by</p>
            <p>{value.update_by.name}</p>
          </div>
          <div className="flex items-center gap-6">
            <p className="w-fit text-2xl font-bold">Role</p>
            <p>{value.update_by.role}</p>
          </div>
          <div className="flex items-center gap-6">
            <p className="w-fit text-2xl font-bold">Date update</p>
            <div className="flex gap-6">
              <p>
                {parseDateWithGmt({
                  date: value.created_at,
                  timezone: currentTimezone?.timezone,
                  format: FORMAT_DATE.hms,
                })}
              </p>
              <p>
                {parseDateWithGmt({
                  date: value.created_at,
                  timezone: currentTimezone?.timezone,
                  format: FORMAT_DATE.ymd,
                })}
              </p>
            </div>
          </div>
        </div>
        {
          <>
            {removeKeyId?.length > 0 ? (
              <div className="mt-4 grid grid-cols-5 gap-3">
                <p className="col-span-1 text-2xl font-bold">Key name</p>
                <p className="col-span-2 text-2xl font-bold">New</p>
                <p className="col-span-2 text-2xl font-bold">Old</p>
              </div>
            ) : (
              <p className="mt-3 text-center">Nothing updated</p>
            )}

            {Array.isArray(removeKeyId) &&
              removeKeyId.map((content) => (
                <div key={content.key} className="mt-2 grid grid-cols-5 gap-3 border-t pt-2">
                  <p className="col-span-1 font-bold">
                    {renderNameKeyUpdateDriver(content.key, t)}
                  </p>
                  <div className="col-span-2">{convertContent(content.key, content.new)}</div>
                  <div className="col-span-2">{convertContent(content.key, content.old)}</div>
                </div>
              ))}
          </>
        }
      </div>
    );
  });

export default function VersionHistory({ id }) {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState();

  const handleClickPage = (value) => {
    setCurrentPage(value + 1);
  };

  const getVersionHistory = async (page) => {
    try {
      const { data } = await axios.get(`${config.API_URL.DRIVERS.GET_VERSION_HISTORY(id)}`, {
        params: { limit: 5, page: page, type: DRIVER_HISTORY_TYPE.VERSION },
        headers: config.headers,
      });

      setHistory(data);
    } catch (error) {
      console.log('🚀 ~ getVersionHistory ~ error:', error);
    }
  };

  useEffect(() => {
    getVersionHistory(currentPage);
  }, [currentPage]);

  if (history?.data.length < 1) return null;

  return (
    <div className="rtl">
      <div className="w-full rounded-lg border p-6">
        <p className="mb-4 text-3xl font-bold">Version History</p>
        {renderHistory(history?.data, t)}

        <div className="mt-5 flex justify-start">
          <Pagination page={currentPage} pages={history?.totalPage} onClick={handleClickPage} />
        </div>
      </div>
    </div>
  );
}
