import React from 'react';
import { ItemInfoIcon } from '../../../../constants/Icon';

export default function ItemInfo({ label, value }) {
  return (
    <div className="flex-none w-1/4 max-w-full py-4 pl-0 pr-3 mt-0">
      <div className="flex mb-2">
        <div className="flex items-center justify-center w-5 h-5 mr-2 text-center bg-center rounded fill-current shadow-soft-2xl bg-gradient-to-tl from-purple-700 to-pink-500 text-neutral-900">
          <svg
            width="10px"
            height="10px"
            viewBox="0 0 40 44"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-1870.000000, -591.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g transform="translate(1716.000000, 291.000000)">
                  <g transform="translate(154.000000, 300.000000)">
                    <path className="color-background" opacity="0.603585379"></path>
                    <path className="color-background" d={ItemInfoIcon}></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <p className="mt-1 mb-0 font-semibold leading-tight text-lg">{label}</p>
      </div>
      <h4 className="font-bold text-4xl py-2">{value}</h4>
      <div className="text-xs h-0.75 flex w-3/4 overflow-visible rounded-lg bg-gray-200">
        <div
          className="duration-600 ease-soft -mt-0.38 -ml-px flex h-1.5 w-3/5 flex-col justify-center overflow-hidden whitespace-nowrap rounded-lg bg-slate-700 text-center text-white transition-all"
          role="progressbar"
          aria-valuenow="60"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
}
