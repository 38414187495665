import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { config } from '../../config/Config';
import axios from 'axios';
import { handleMessage } from '../../helper/utils';

const CONFIRM = 'confirm-odm-formula';

export default function FormulaForm({
  isOpen,
  setIsOpen,
  valueAvailability,
  valueDistance,
  valueIdleTime,
  valueQualified,
  activeAvailability,
  activeDistance,
  activeIdleTime,
}) {
  const [valueConfirm, setValueConfirm] = useState('');

  const closeModal = () => {
    setValueConfirm('');
    setIsOpen(false);
  };

  const handleConfirm = (e) => {
    setValueConfirm('');
    if (e.target.value === CONFIRM) {
      setValueConfirm(e.target.value);
    }
  };

  const handleSubmit = async () => {
    const data = {
      availability_weight: activeAvailability.Availability ? Number(valueAvailability) : 0,
      distance_weight: activeDistance.Distance ? Number(valueDistance) : 0,
      idle_time_weight: activeIdleTime.Idle_time ? Number(valueIdleTime) : 0,
      qualified_limit: Number(valueQualified),
    };
    try {
      const res = await axios.put(config.API_URL.SETTING.ODM, data, {
        headers: config.headers,
      });
      setValueConfirm('');
      if (res.status === 200) {
        setIsOpen(false);
        return handleMessage('success', 'Update order distribution formula successfully');
      }
      handleMessage('error', 'Update order distribution formula fail');
    } catch (error) {
      console.log('🚀 ~ getDetailLog ~ error:', error);
    }
  };
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block max-w-[352px] h-full overflow-x-hidden overflow-y-auto my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="p-8">
                  <Dialog.Title
                    as="h1"
                    className="font-bold text-2xl leading-6 text-gray-900 text-right"
                  >
                    Are you sure to save the formula?
                  </Dialog.Title>
                  <p className="mt-2 text-right">
                    Your changes will be applied immediately for incoming orders. Make sure you’ve
                    tested the formula before saving.
                  </p>
                </div>
                <div className="p-8 border-t border-b">
                  <p className="mb-4">
                    Please type <span className="font-bold">{CONFIRM}</span> to save the formula.
                  </p>
                  <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                    <input
                      onChange={handleConfirm}
                      placeholder={CONFIRM}
                      type="text"
                      className="w-full outline-none"
                    />
                  </div>
                </div>
                <div className="px-8 pb-8 pt-4 flex gap-4">
                  <button
                    onClick={closeModal}
                    className="h-[35px] focus:outline-none text-white bg-gray-600 hover:bg-gray-300 focus:ring-1 focus:ring-gray-200 dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-700 font-medium rounded-lg text-xl px-12 py-2.5"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!valueConfirm}
                    onClick={handleSubmit}
                    className={`${
                      !valueConfirm
                        ? 'text-black bg-gray-100'
                        : 'text-white bg-green-600 hover:bg-green-700'
                    } h-[35px] focus:outline-none focus:ring-1 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-700 font-medium rounded-lg text-xl px-12 py-2.5`}
                  >
                    Save formula
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
