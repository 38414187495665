export default function updateQuantities(jsonData, oldItemQuantity, newItemQuantity) {
  // Tinh toan ti le:
  const ratio = newItemQuantity / oldItemQuantity;

  // Cập nhật từng quantities cua mỗi option
  const updatedOptions = jsonData.options.map((option) => ({
    ...option,
    quantity: Math.ceil(option.quantity), // Rounding up to the nearest integer
  }));

  // Update the item_quantity
  jsonData.order_q = newItemQuantity;

  // Replace the options with the updated ones
  jsonData.options = updatedOptions;

  return jsonData;
}
