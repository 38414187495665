import { Chart } from 'chart.js';
import { BAR } from '../../../constants/constants';
export const DriverChartConstants = {
  label: 'Hours',
  color: 'white',
  mode: 'index',
  family: 'Open Sans',
  style: 'normal',
};
export const driverChart = ({ ctx, dataChart, t }) =>
  new Chart(ctx, {
    type: BAR,
    data: {
      labels: dataChart.title,

      datasets: [
        {
          label: DriverChartConstants.label,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 4,
          borderSkipped: false,
          backgroundColor: DriverChartConstants.color,
          data: dataChart.values,
          maxBarThickness: 6,
          time: dataChart.time,
        },
      ],
    },

    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const valueChartY = t('driver_hours') + tooltipItem?.parsed?.y;
              return valueChartY;
            },
            title: (tooltipItem) => {
              const valueTitle = tooltipItem[0].dataset?.time[tooltipItem[0].dataIndex];
              return valueTitle;
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: DriverChartConstants.mode,
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 600,
            beginAtZero: true,
            padding: 15,
            font: {
              size: 11,
              family: DriverChartConstants.family,
              style: DriverChartConstants.style,
              lineHeight: 2,
            },
            color: '#fff',
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 600,
            beginAtZero: true,
            padding: 6,
            font: {
              size: 11,
              family: DriverChartConstants.family,
              style: DriverChartConstants.style,
              lineHeight: 2,
            },
            color: '#fff',
          },
        },
      },
    },
  });
