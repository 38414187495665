const useSchema = () => {
  const schema = [
    {
      name: 'Driver',
      selector: 'driver',
    },

    {
      name: 'Availability weight',
      selector: 'availability_weight',
    },
    {
      name: 'Availability score',
      selector: 'availability_score',
    },

    {
      name: 'Distance weight',
      selector: 'distance_weight',
    },
    {
      name: 'Distance score',
      selector: 'distance_score',
    },

    {
      name: 'Idle time weight',
      selector: 'idle_time_weight',
    },
    {
      name: 'Idle time score',
      selector: 'idle_time_score',
    },

    {
      name: 'Qualified limit',
      selector: 'qualified_limit',
    },
    {
      name: 'Result',
      selector: (row) => (
        <p className={`${row.result ? 'text-[#047857]' : 'text-[#B45309]'}`}>
          {row.result ? <span>Qualified</span> : <span>Disqualified</span>}
        </p>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: 12,
        background: '#F9F9F9',
        justifyContent: 'flex-start',
        padding: 12,
      },
    },
    rows: {
      style: {
        cursor: 'pointer',
        fontSize: 12,
      },
    },
  };

  return { schema, customStyles };
};
export default useSchema;
