import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IconInformation } from '../../constants/Icon';

export default function GenerateTestResults({ isOpen, setIsOpen, valueOdmWeightAge, recordTest }) {
  const [scoreAvailability, setScoreAvailability] = useState(0);
  const [scoreDistance, setScoreDistance] = useState(0);
  const [scoreIdleTime, setScoreIdleTime] = useState(0);
  const [valueAvailability, setValueAvailability] = useState();
  const [valueDistance, setValueDistance] = useState();
  const [valueIdleTime, setValueIdleTime] = useState();
  const [valueQualified, setValueQualified] = useState();
  const [error, setError] = useState('');
  const [errorAvailability, setErrorAvailability] = useState('');
  const [errorDistance, setErrorDistance] = useState('');
  const [errorIdleTime, setErrorIdleTime] = useState('');
  const [errorQualified, setErrorQualified] = useState('');
  const [errorScoreAvailability, setErrorScoreAvailability] = useState('');
  const [errorScoreDistance, setErrorScoreDistance] = useState('');
  const [errorScoreIdleTime, setErrorScoreIdleTime] = useState('');
  const [openInformation, setOpenInformation] = useState(false);
  const [isQualified, setIsQualified] = useState(null);

  const closeModal = () => {
    setIsOpen(false);
    setScoreAvailability(0);
    setScoreDistance(0);
    setScoreIdleTime(0);
    setIsQualified(null);
    setError('');
    setErrorAvailability('');
    setErrorDistance('');
    setErrorQualified('');
    setErrorScoreAvailability('');
    setErrorScoreDistance('');
    setErrorScoreIdleTime('');
  };

  const handleInput = (e, setEnable, setErrorInput) => {
    setError('');
    setErrorQualified('');
    setEnable(e.target.value);
    if (!e.target.value) {
      return setErrorInput('Value must be a number');
    }
    setErrorInput('');
  };

  const handleInputScore = (e, setEnable, setErrorInput) => {
    setEnable(e.target.value);
    if (e.target.value < 0 || e.target.value > 10) {
      return setErrorInput('Value score must be between 0 and 10');
    }
    if (!e.target.value) {
      return setErrorInput('Value must be a number');
    }
    setErrorInput('');
  };

  const onBlur = () => {
    setOpenInformation(false);
  };

  const handleSubmit = () => {
    const qualified_limit =
      Number(valueAvailability) + Number(valueDistance) + Number(valueIdleTime);
    if (qualified_limit !== 100) {
      setError('Total percentage of Availability, Distance, Idle Time must be equal to 100%');
    }
    if (valueQualified > 100 || valueQualified < 0) {
      setErrorQualified('Qualified limit must be between 0 and 100');
    }
    if (
      qualified_limit !== 100 ||
      valueQualified > 100 ||
      valueQualified < 0 ||
      scoreAvailability < 0 ||
      scoreDistance < 0 ||
      scoreIdleTime < 0 ||
      scoreAvailability > 10 ||
      scoreDistance > 10 ||
      scoreIdleTime > 10
    )
      return;

    let result = false;
    try {
      const driverScore =
        (scoreAvailability * (valueAvailability / 100) +
          scoreDistance * (valueDistance / 100) +
          scoreIdleTime * (valueIdleTime / 100)) *
        10;

      if (driverScore >= valueQualified) {
        result = true;
        setIsQualified(result);
      } else {
        setIsQualified(false);
      }
      recordTest({
        driver: 'Locate-Maeda',
        availability_weight: valueAvailability,
        availability_score: scoreAvailability,
        distance_weight: valueDistance,
        distance_score: scoreDistance,
        idle_time_weight: valueIdleTime,
        idle_time_score: scoreIdleTime,
        qualified_limit: valueQualified,
        result,
        driverScore,
      });
    } catch (error) {
      console.log('🚀 ===== error:', error);
    }
  };

  const renderResult = () => {
    if (isQualified) return 'Qualified';
    if (isQualified === null) return 'Result';
    return 'Disqualified';
  };

  useEffect(() => {
    setValueAvailability(valueOdmWeightAge?.availability_weight);
    setValueDistance(valueOdmWeightAge?.distance_weight);
    setValueIdleTime(valueOdmWeightAge?.idle_time_weight);
    setValueQualified(valueOdmWeightAge?.qualified_limit);
  }, [valueOdmWeightAge, isOpen]);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto rtl" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block p-6 min-w-[560px] h-full overflow-x-hidden overflow-y-auto my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h1"
                  className="font-bold text-2xl leading-6 text-gray-900 text-center pb-4 border-b mb-6"
                >
                  Test ODM score
                </Dialog.Title>

                {error && (
                  <div className="flex justify-between">
                    <p className="text-red-600 w-fit flex gap-2 items-center mb-6 p-2 rounded-lg bg-red-200">
                      <IconInformation />
                      {error}
                    </p>
                    <p></p>
                  </div>
                )}

                <div className="mb-4">
                  <p className="font-bold text-right mb-2">Driver</p>
                  <div className="px-3 py-2 mb-[5px] bg-gray-200 rounded-md border">
                    <input
                      defaultValue="Locate-Maeda"
                      disabled
                      type="text"
                      className="w-full outline-none text-gray-400"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <p className="font-bold text-right mb-2">Availability</p>
                  <div className="flex gap-2">
                    <div className="w-1/2">
                      <p className="text-right mb-2">Weight</p>
                      <div
                        className={` ${
                          (error || errorAvailability) && 'border-red-600'
                        } flex justify-between px-3 py-2 mb-[5px] rounded-md border items-center`}
                      >
                        <input
                          value={valueAvailability}
                          onChange={(e) =>
                            handleInput(e, setValueAvailability, setErrorAvailability)
                          }
                          type="number"
                          className="w-full outline-none bg-transparent"
                        />
                        <p className="text-[#3B82F6]">%</p>
                      </div>
                      {errorAvailability && (
                        <p className="text-red-600 text-center">{errorAvailability}</p>
                      )}
                    </div>
                    <div className="w-1/2">
                      <p className="text-right mb-2">Score</p>
                      <div
                        className={`${
                          errorScoreAvailability && 'border-red-600'
                        } px-3 py-2 mb-[5px] rounded-md border items-center`}
                      >
                        <input
                          value={scoreAvailability}
                          onChange={(e) =>
                            handleInputScore(e, setScoreAvailability, setErrorScoreAvailability)
                          }
                          type="number"
                          className="w-full outline-none"
                        />
                      </div>
                      {errorScoreAvailability && (
                        <p className="text-red-600 text-center">{errorScoreAvailability}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <p className="font-bold text-right mb-2">Distance</p>
                  <div className="flex gap-2">
                    <div className="w-1/2">
                      <p className="text-right mb-2">Weight</p>
                      <div
                        className={` ${
                          (error || errorDistance) && 'border-red-600'
                        } flex justify-between px-3 py-2 mb-[5px] rounded-md border items-center`}
                      >
                        <input
                          value={valueDistance}
                          onChange={(e) => handleInput(e, setValueDistance, setErrorDistance)}
                          type="number"
                          className="w-full outline-none bg-transparent"
                        />
                        <p className="text-[#3B82F6]">%</p>
                      </div>
                      {errorDistance && <p className="text-red-600 text-center">{errorDistance}</p>}
                    </div>
                    <div className="w-1/2">
                      <p className="text-right mb-2">Score</p>
                      <div
                        className={`${
                          errorScoreDistance && 'border-red-600'
                        } px-3 py-2 mb-[5px] rounded-md border items-center`}
                      >
                        <input
                          value={scoreDistance}
                          onChange={(e) =>
                            handleInputScore(e, setScoreDistance, setErrorScoreDistance)
                          }
                          type="number"
                          className="w-full outline-none"
                        />
                      </div>
                      {errorScoreDistance && (
                        <p className="text-red-600 text-center">{errorScoreDistance}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <p className="font-bold text-right mb-2">Idle time</p>
                  <div className="flex gap-2">
                    <div className="w-1/2">
                      <p className="text-right mb-2">Weight</p>
                      <div
                        className={` ${
                          (error || errorIdleTime) && 'border-red-600'
                        } flex justify-between px-3 py-2 mb-[5px] rounded-md border items-center`}
                      >
                        <input
                          value={valueIdleTime}
                          onChange={(e) => handleInput(e, setValueIdleTime, setErrorIdleTime)}
                          type="number"
                          className="w-full outline-none bg-transparent"
                        />
                        <p className="text-[#3B82F6]">%</p>
                      </div>
                      {errorIdleTime && <p className="text-red-600 text-center">{errorIdleTime}</p>}
                    </div>
                    <div className="w-1/2">
                      <p className="text-right mb-2">Score</p>
                      <div
                        className={`${
                          errorScoreIdleTime && 'border-red-600'
                        } px-3 py-2 mb-[5px] rounded-md border items-center`}
                      >
                        <input
                          value={scoreIdleTime}
                          onChange={(e) =>
                            handleInputScore(e, setScoreIdleTime, setErrorScoreIdleTime)
                          }
                          type="number"
                          className="w-full outline-none"
                        />
                      </div>
                      {errorScoreIdleTime && (
                        <p className="text-red-600 text-center">{errorScoreIdleTime}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="flex min-w-[130px] mb-[5px] items-center gap-1 font-bold">
                    <p>Qualified limit</p>
                    <div className="relative flex items-center">
                      <button onClick={() => setOpenInformation(!openInformation)} onBlur={onBlur}>
                        <IconInformation />
                      </button>

                      {openInformation && (
                        <div className="px-3 py-2 w-[300px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                          The admin are allow to set a qualified limit: For example If the score is
                          below 70, that shipper is not qualified to receive an incoming order
                          notification
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <div
                      className={`px-3 py-2 mb-[5px] rounded-md border items-center ${
                        errorQualified && 'border-red-600'
                      }`}
                    >
                      <input
                        value={valueQualified}
                        type="number"
                        className="w-full outline-none"
                        onChange={(e) => handleInput(e, setValueQualified, setErrorQualified)}
                      />
                    </div>
                    {errorQualified && <p className="text-red-600 text-center">{errorQualified}</p>}
                  </div>
                </div>

                <div className="mb-4">
                  <p className="font-bold text-right mb-2">Result</p>
                  <div
                    className={`${
                      isQualified
                        ? 'bg-[#D1FAE5] border-[#A7F3D0] text-[#047857]'
                        : isQualified === null
                        ? 'bg-[#F3F4F6] border-[#E5E7EB]'
                        : 'bg-[#FEF4C7] border-[#FDE68A] text-[#B45309]'
                    } px-3 py-2 mb-[5px] rounded-md border font-bold text-center`}
                  >
                    {renderResult()}
                  </div>
                </div>

                <button
                  onClick={handleSubmit}
                  className="h-[35px] w-full focus:outline-none text-white bg-gray-600 hover:bg-gray-300 focus:ring-1 focus:ring-gray-200 font-medium rounded-lg text-xl"
                >
                  Test formula
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
