import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import colBalance from '../../Transactions/colBalance';
// import { defaultCompany } from '../../Drivers/EditDriver';
import { renderStar } from '../../Rating/useColumn';

const useSchema = () => {
  const { t } = useTranslation();
  const schema = [
    {
      name: t('rating_star'),
      selector: (row) => (row.rating_star ? renderStar(row.rating_star) : '...'),
      style: {
        with: 100,
      },
      sortable: true,
    },
    {
      name: t('CODE'),
      selector: (row) => row.passKey?.toUpperCase(),
      style: {
        with: 100,
      },
    },
    // {
    //   name: t('transaction.previous_balance'),
    //   minWidth: '200px',
    //   cell: (row) =>
    //     colBalance(
    //       {
    //         transactionBalanceSub: row?.transactionBalanceSub,
    //         transactionBalanceMain: row?.transactionBalanceMain,
    //       },
    //       'previous'
    //     ),
    // },
    // {
    //   name: t('transaction.remaining_balance'),
    //   minWidth: '200px',
    //   cell: (row) =>
    //     colBalance(
    //       {
    //         transactionBalanceSub: row?.transactionBalanceSub,
    //         transactionBalanceMain: row?.transactionBalanceMain,
    //       },
    //       'remaining'
    //     ),
    // },
    {
      name: t('company'),
      selector: 'company_name',
      sortable: true,
    },
    {
      name: t('name'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('civil_registry'),
      selector: 'id_number',
    },
    {
      name: t('cell_phone'),
      selector: 'phone',
    },
    {
      name: t('driver_city'),
      selector: 'city',
      sortable: true,
    },
    {
      name: t('balance'),
      selector: (row) =>
        `${
          row.balance
            ? row.balance?.toFixed(2)
            : row?.driver?.new_balance
            ? row?.driver?.new_balance.toFixed(2)
            : 0
        }`,
      sortable: true,
    },
    {
      name: t('gift'),
      selector: (row) => `${row.subBalance ? row.subBalance?.toFixed(2) : 0}`,
      sortable: true,
    },
    {
      name: t('stcPay'),
      selector: 'stcPay',
      sortable: true,
    },
    {
      name: t('subscription_date'),
      selector: (row) => `${moment(row.registration_date).calendar('LL ~ hh:mm A')}`,
      sortable: true,
    },
    {
      name: t('status'),
      selector: (row) => `${row.isActive ? t('active') : t('inactive')}`,
      sortable: true,
    },
  ];

  return schema;
};
export default useSchema;
