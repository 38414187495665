import { useTranslation } from 'react-i18next';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';

const useSchema = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const schema = [
    {
      name: t('url'),
      selector: 'url',
      sortable: true,
      style: {
        padding: 0,
      },
    },
    {
      name: t('method'),
      selector: 'method',
      sortable: true,
      width: '150px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('status'),
      selector: (row) => `${row.status}`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('driver_id'),
      selector: (row) => `${row?.filters?.driver?.id_number || 'NULL'}`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('orders'),
      selector: (row) =>
        `${row?.filters?.order?.order_number || row?.params?.order_number || 'NULL'}`,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('action'),
      selector: (row) => row.type,
      sortable: true,
      width: '200px',
      style: {
        padding: 0,
      },
    },
    {
      name: t('date_time'),
      selector: (row) =>
        parseDateWithGmt({
          date: row.created_at,
          timezone: currentTimezone?.timezone,
          format: FORMAT_DATE.ymdhms,
        }),
      sortable: true,
      width: '300px',
      wrap: true,
      style: {
        padding: 0,
      },
    },
  ];
  return schema;
};

export default useSchema;
