import React, { useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IconClose } from '../../constants/Icon';
import InputAndLabel from './InputAndLabel';
import CustomSelectReact from '../CustomSelectReact';
import { toast } from 'react-toastify';
import { config } from '../../config/Config';
import axiosInstance from '../../helper/axios/axiosInstance';
import { debounce } from 'lodash';

export default function AddSubScription({
  visible,
  companies,
  toggleModal,
  getDataSubscription,
  defaultSubscription,
}) {
  const { t } = useTranslation();

  const typeStatus = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('inactive'), value: 'INACTIVE' },
    { label: t('draft'), value: 'DRAFT' },
  ];
  const [values, setValues] = useState({});

  const handleFilter = (value, type) => {
    if (type === 'company' || type === 'status') {
      const newValues = {
        ...values,
        [type]: value,
      };
      setValues(newValues);
      return;
    }
    const newValues = {
      ...values,
      [type]: value?.target?.value,
    };

    setValues(newValues);
  };

  const handleAddSubscription = async () => {
    const { company, status, ...data } = values;
    const body = {
      ...data,
      company_id: company?.id ?? undefined,
    };

    const dataEdit = {
      ...data,
      status: status?.value ?? undefined,
    };

    if (defaultSubscription?._id) {
      const idSubscription = defaultSubscription?._id;
      try {
        await axiosInstance.patch(
          `${config.API_URL.SUBSCRIPTION.GET_LIST}/${idSubscription}`,
          dataEdit,
          { headers: config.headers }
        );

        toast(t('edit_success'));
        toggleModal(false);
        getDataSubscription();
      } catch (error) {
        toast.error(t(`edit_fail`));
      }
      return;
    }

    const arrayNew = Object.values(body);
    if (arrayNew.length < 5) {
      toast.error(t('please_fill_in_all_fields'), { position: toast.POSITION.BOTTOM_LEFT });
      return;
    }

    try {
      await axiosInstance.post(`${config.API_URL.SUBSCRIPTION.GET_LIST}`, body, {
        headers: config.headers,
      });

      toast(t('create_success'));
      toggleModal(false);
      getDataSubscription();
      setValues({});
    } catch (error) {
      toast.error(t(`create_fail`));
    }
  };

  const debounceAddSubscription = debounce(handleAddSubscription, 500);
  function closeModal() {
    toggleModal(false);
    setValues({});
  }
  const renderAdd = () => {
    return (
      <div className="inline-block w-[700px] overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">{t('add_subscription')}</span>
          </div>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>
        <div className="flex flex-col pl-6 my-10 text-right transition-all gap-y-10">
          <InputAndLabel
            label={t('subscription_title')}
            placeholder={t('subscription_title')}
            onChange={(e) => handleFilter(e, 'subscriptionName')}
            defaultValue={defaultSubscription?.subscriptionName}
          />
          <div className="flex justify-start gap-3">
            <div className={`${defaultSubscription?._id ? 'w-1/2' : 'w-full'}`}>
              <p className="mb-3 text-2xl font-semibold">{t('company')}</p>
              <CustomSelectReact
                isClearable
                isDisabled={defaultSubscription?.company_id ? true : false}
                placeholder={t('company')}
                onChange={(e) => handleFilter(e, 'company')}
                className="filter-select min-w-[200px]"
                options={companies}
                defaultValue={
                  defaultSubscription?.company_id
                    ? {
                        label: defaultSubscription?.company_info?.name,
                        value: defaultSubscription?.company_id,
                      }
                    : undefined
                }
              />
            </div>
            {defaultSubscription?._id && (
              <div className="w-1/2">
                <p className="mb-3 text-2xl font-semibold">{t('status')}</p>
                <CustomSelectReact
                  isClearable
                  placeholder={t('status')}
                  onChange={(e) => handleFilter(e, 'status')}
                  className="filter-select min-w-[200px]"
                  options={typeStatus}
                  defaultValue={{
                    value: defaultSubscription?.status,
                    label: t(String(defaultSubscription?.status).toLowerCase()),
                  }}
                />
              </div>
            )}
          </div>

          <div className="flex justify-start gap-3">
            <div className="w-1/2">
              <InputAndLabel
                defaultValue={defaultSubscription?.price}
                label={t('price')}
                type="number"
                placeholder={t('price')}
                onChange={(e) => handleFilter(e, 'price')}
              />
            </div>

            <div className="w-1/2">
              <InputAndLabel
                type="number"
                defaultValue={defaultSubscription?.duration}
                label={t('duration')}
                placeholder={t('duration')}
                onChange={(e) => handleFilter(e, 'duration')}
              />
            </div>
          </div>

          <div className="w-full">
            <p className="mb-3 text-2xl font-semibold">{t('description')}</p>

            <textarea
              className="w-full pr-3 border rounded-md"
              rows="4"
              onChange={(e) => handleFilter(e, 'description')}
              placeholder={t('description')}
              defaultValue={defaultSubscription?.description}
            />
          </div>

          {!defaultSubscription?._id && (
            <div className="w-full">
              <p className="mb-3 text-2xl font-semibold">{t('Draft')}</p>

              <div className="flex items-center row gap-x-4">
                <label className="switch">
                  <input
                    checked={values?.isDraft}
                    type="checkbox"
                    name="isEnable"
                    onChange={(e) => {
                      setValues((value) => {
                        return { ...value, isDraft: e.target.checked };
                      });
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-3">
          <button
            onClick={debounceAddSubscription}
            type="button"
            className="ml-12 font-medium rounded-lg text-xl px-12 py-2.5 h-[35px] justify-center bg-green-600 hover:bg-green-700 focus:ring-1 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-700 items-center flex focus:outline-none text-white"
          >
            {t('add')}
          </button>
          <button
            onClick={closeModal}
            type="button"
            className="ml-12 font-medium rounded-lg text-xl px-12 py-2.5 h-[35px] hover:bg-gray-300 justify-center items-center flex border"
          >
            {t('close')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderAdd()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
