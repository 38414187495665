import React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IconClose } from '../../constants/Icon';

export default function DetailLogExport({ visible, dataFocus, toggleModal }) {
  const { t } = useTranslation();

  function closeModal() {
    toggleModal(false);
  }
  const renderRatingDetail = () => {
    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">{t('log_export_detail')}</span>
          </div>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>
        <div className="my-10 pl-6 text-right flex flex-col gap-y-10 overflow-y-auto transition-all">
          <div>
            <p className="font-bold text-3xl">{t('createdBy')}</p>
            <div className="grid grid-cols-4 gap-x-4 mt-6 gap-y-3">
              <p className="font-bold text-2xl col-span-1">
                {t('delivery_company.partner.register.first_name')}
              </p>
              <p className="col-span-3 text-2xl">{dataFocus?.createdBy?.first_name}</p>
              <p className="font-bold col-span-1">
                {t('delivery_company.partner.register.last_name')}
              </p>
              <p className="col-span-3">{dataFocus?.createdBy?.last_name}</p>
              <p className="font-bold col-span-1">{t('phone')}</p>
              <p className="col-span-3">{dataFocus?.createdBy?.phone}</p>
              <p className="font-bold col-span-1">{t('email')}</p>
              <p className="col-span-3">{dataFocus?.createdBy?.email}</p>
              <p className="font-bold col-span-1">{t('url')}</p>
              <a
                className="text-blue-700 hover:text-blue-400 col-span-3 truncate"
                href={dataFocus?.url}
              >
                {dataFocus?.url}
              </a>
            </div>

            <div className="mt-10">
              <p className="font-bold text-2xl mb-2">{t('filter')}</p>
              <div className="text-left">
                <textarea
                  className="prettyprint w-full resize-none bg-white"
                  disabled
                  value={JSON.stringify(dataFocus?.filter, undefined, 2)}
                  style={{ height: 600 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderRatingDetail()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
