import { RadioGroup } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
const dates = (t) => [
  { label: t('year'), value: 'month' },
  { label: t('month'), value: 'day' },
  { label: t('week'), value: 'week' },
];

const SelectOption = ({ value, setSelected }) => {
  const { t } = useTranslation();

  return (
    <RadioGroup value={value} onChange={setSelected}>
      <div className="flex flex-row gap-x-3">
        {dates(t).map((date) => (
          <RadioGroup.Option
            key={date.value}
            value={date.value}
            className={({ active, checked }) => {
              const checkValueActive = active
                ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                : '';
              const checkValueChecked = checked
                ? 'bg-sky-900 bg-opacity-75 text-white'
                : 'bg-white';

              return `${checkValueActive} ${checkValueChecked} w-[30px] h-[30px] justify-center items-center z-20 relative flex cursor-pointer rounded-lg shadow-md focus:outline-none`;
            }}
          >
            {({ active, checked }) => {
              const checkValueChecked = checked ? 'text-black font-extrabold' : 'text-gray-500 ';
              return (
                <>
                  <div className="flex items-center justify-center">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium flex justify-center items-center ${checkValueChecked}`}
                        >
                          <div className="flex justify-center items-center text-[10px]">
                            {date.label}
                          </div>
                        </RadioGroup.Label>
                      </div>
                    </div>
                  </div>
                </>
              );
            }}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
export default SelectOption;
