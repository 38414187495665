import { useTranslation } from 'react-i18next';
import { Start, StartOutLine } from '../../constants/Icon';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
import { renderFields, renderRatingColor } from '../../constants/constants';
const { useMemo } = require('react');

const useColumn = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('order_number'),
        selector: 'order_number',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.order_number),
      },

      {
        name: t('shukah.customer.phone'),
        selector: 'contact_phone',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.contact_phone),
      },

      {
        name: t('shukah.customer.name'),
        selector: 'customer_name',
        sortable: true,
        minWidth: '140px',
        cell: (row) => (
          <div className="flex flex-row items-center justify-center">
            <span className="w-6 h-6 mx-1">
              {row?.star ? (
                <Start color={renderRatingColor(row.driver_star)} />
              ) : (
                <StartOutLine color="#D4E9D8" />
              )}
            </span>

            {row?.driver_star && (
              <span className="ltr">{renderFields(row?.driver_star?.toFixed(1))}</span>
            )}
          </div>
        ),
      },
      {
        name: t('store_star'),
        selector: 'store_star',
        sortable: true,
        minWidth: '140px',
        cell: (row) => (
          <div className="flex flex-row items-center justify-center">
            <span className="w-6 h-6 mx-1">
              {row?.store_star ? (
                <Start color={renderRatingColor(row.store_star)} />
              ) : (
                <StartOutLine color="#D4E9D8" />
              )}
            </span>

            {row?.store_star && (
              <span className="ltr">{renderFields(row?.store_star?.toFixed(1))}</span>
            )}
          </div>
        ),
      },
      {
        name: t('driver_feedback'),
        selector: 'driver_feedback',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.driver_feedback),
      },

      {
        name: t('store_feedback'),
        selector: 'store_feedback',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.store_feedback),
      },

      {
        name: t('date'),

        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
          }),
        sortable: true,
        wrap: true,
        minWidth: '120px',
      },
      {
        name: t('date_time'),
        selector: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
            format: FORMAT_DATE.hms,
          }),
        sortable: true,
        wrap: true,
        minWidth: '140px',
      },
    ],
    []
  );
  return { columns };
};

export { useColumn };
