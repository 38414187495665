import React from 'react';

export default function InputAndLabel({ label, ...props }) {
  return (
    <div>
      <p className="mb-3 text-2xl font-semibold">{label}</p>
      <div className="input-search-wrapper">
        <div className="input-wrapper">
          <input type="text" className="input-search" {...props} />
        </div>
      </div>
    </div>
  );
}
