import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../commonComponents/LoadingButton';
import CustomSelectReact from '../../CustomSelectReact';

import useFilter from './useFilter';
import DatePicker from 'react-date-picker';
import useFilterOptionDriverNew from './useFilterOptionDriverNew';
import useEventEnter from '../../../hooks/useEventEnter';
function Filters(
  { isLoading = false, setCurrentPage, handleGetDataTable, buildFilterOrderWithRef, currentPage },
  refFilters
) {
  const { handlerFiltersInput, selectHandlerSelect, handleDateFilter, onApplyFilter } = useFilter({
    setCurrentPage,
    handleGetDataTable,
    refFilters,
    buildFilterOrderWithRef,
    currentPage,
  });
  const { t } = useTranslation();
  const { filterStatus, companies, cities } = useFilterOptionDriverNew();

  useEventEnter(onApplyFilter);

  return (
    <div className="search-order flex flex-col mb-[35px] rtl items-start	">
      <div className=" py-5 flex items-center gap-4 flex-wrap mb-4">
        <div className="flex">
          <input
            placeholder={t('firstName')}
            className="filter-input"
            name="firstName"
            onChange={handlerFiltersInput}
            ref={(ref) => (refFilters.current.firstName = ref)}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('lastName')}
            className="filter-input"
            name="lastName"
            ref={(ref) => (refFilters.current.lastName = ref)}
            onChange={handlerFiltersInput}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('phone')}
            className="filter-input"
            name="phone"
            ref={(ref) => (refFilters.current.phone = ref)}
            onChange={handlerFiltersInput}
          />
        </div>
        <div className="flex">
          <input
            placeholder={t('id_number')}
            className="filter-input"
            name="id_number"
            ref={(ref) => (refFilters.current.id_number = ref)}
            onChange={handlerFiltersInput}
          />
        </div>

        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('city')}
            className="filter-select min-w-[200px]"
            options={cities}
            value={refFilters?.current?.cities || []}
            onChange={(e) => selectHandlerSelect(e, 'cities')}
            isMulti
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('delivery_company_filter')}
            className="filter-select min-w-[200px]"
            options={companies}
            value={refFilters?.current?.company || []}
            onChange={(e) => selectHandlerSelect(e, 'company')}
            isMulti
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isDisabled={isLoading}
            placeholder={t('status')}
            className="filter-select min-w-[200px]"
            options={filterStatus}
            value={refFilters?.current?.status || []}
            onChange={(e) => selectHandlerSelect(e, 'status')}
            isMulti
          />
        </div>

        <div className="input-select-wrapper ltr">
          <DateTimeRangePicker
            className="input-select-input-wrapper !border-0 w-[200px]"
            disabled={isLoading}
            value={refFilters.current?.date}
            onChange={(value) => handleDateFilter(value, 'date')}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
        {/* <div className="input-select-wrapper ltr">
          <div className="flex flex-row items-center w-[240px] justify-between">
            <DatePicker
              disabled={isLoading}
              onChange={(value) => handleDateFilter(value, 'transactionDate')}
              value={refFilters.current?.transactionDate}
              format={'y-MM-dd'}
            />
            <div className="mr-8">{t('date_balance')}</div>
          </div>
        </div> */}
        <div className="relative w-full">
          <div className="absolute right-0 ">
            <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(Filters);
