import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './monthYearPicker.css';
import { ArrowBack, ArrowNext } from '../../constants/Icon';
import { DAY, WEEK } from '../../constants/constants';

class MonthYearPicker extends PureComponent {
  constructor(props) {
    super(props);
    this.handleOnClickLeftArrow = this.handleOnClickLeftArrow.bind(this);
    this.handleOnClickRightArrow = this.handleOnClickRightArrow.bind(this);
  }

  handleOnClickLeftArrow() {
    if (this.props.selectedYear <= this.props.minYear) {
      return;
    }
    this.props.onChangeYear(this.props.selectedYear - 1);
  }

  handleOnClickRightArrow() {
    if (this.props.selectedYear >= this.props.maxYear) {
      return;
    }
    this.props.onChangeYear(this.props.selectedYear + 1);
  }

  renderMonth(month) {
    let text = '';
    switch (month) {
      case 1:
        text = 'Jan';
        break;
      case 2:
        text = 'Feb';
        break;
      case 3:
        text = 'Mar';
        break;
      case 4:
        text = 'Apr';
        break;
      case 5:
        text = 'May';
        break;
      case 6:
        text = 'Jun';
        break;
      case 7:
        text = 'Jul';
        break;
      case 8:
        text = 'Aug';
        break;
      case 9:
        text = 'Sep';
        break;
      case 10:
        text = 'Oct';
        break;
      case 11:
        text = 'Nov';
        break;
      case 12:
        text = 'Dec';
        break;
      default:
        break;
    }
    const className = this.props.selectedMonth === month ? 'selected' : '';
    return (
      <div
        className={`${className} rounded-md`}
        role="button"
        tabIndex={0}
        onClick={() => this.props.onChangeMonth(month)}
      >
        <span>{text}</span>
      </div>
    );
  }

  renderWeek(week) {
    let textFilterWeek = `${week} ${WEEK}` || '';
    const className = this.props.selectedWeek === week ? 'bg-gray-400' : '';
    return (
      <div
        className={`${className} hover:bg-gray-400 border p-1 mt-2 text-center rounded-md`}
        role="button"
        tabIndex={0}
        onClick={() => this.props.onChangeWeek(week)}
      >
        <span>{textFilterWeek}</span>
      </div>
    );
  }

  renderLeftArrowButton() {
    if (this.props.selectedYear === this.props.minYear) {
      return (
        <div className="disabled w-8 h-8 opacity-25">
          <ArrowBack />
        </div>
      );
    }
    return (
      <div role={'button'} onClick={this.handleOnClickLeftArrow} className="w-8 h-8">
        <ArrowBack />
      </div>
    );
  }

  renderRightArrowButton() {
    if (this.props.selectedYear === this.props.maxYear) {
      return (
        <div className="disabled w-8 h-8 opacity-25">
          <ArrowNext />
        </div>
      );
    }
    return (
      <div role={'button'} onClick={this.handleOnClickRightArrow} className="w-8 h-8">
        <ArrowNext />
      </div>
    );
  }

  render() {
    const { selectedOption } = this.props;
    const valuesFilter = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    return (
      <div className="month-year-picker">
        <div className="flex flex-row justify-center items-center py-4 relative filter-by">
          <span className="text-[#5186ed] text-[20px]">{this.props.selectedYear}</span>
          <div className="flex flex-row absolute right-0">
            {this.renderLeftArrowButton()}
            {this.renderRightArrowButton()}
          </div>
        </div>
        {selectedOption === DAY && (
          <div className="month-picker">
            <div>{valuesFilter.slice(0, 4).map((value) => this.renderMonth(value))}</div>
            <div>{valuesFilter.slice(4, 8).map((value) => this.renderMonth(value))}</div>
            <div>{valuesFilter.slice(8, 12).map((value) => this.renderMonth(value))}</div>
          </div>
        )}
        {selectedOption === WEEK && (
          <div className="px-4">
            {valuesFilter.slice(3, 7).map((value) => this.renderWeek(value))}
          </div>
        )}
      </div>
    );
  }
}

MonthYearPicker.propTypes = {
  selectedYear: PropTypes.number.isRequired,
  selectedMonth: PropTypes.number.isRequired,
  selectedWeek: PropTypes.number.isRequired,
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onChangeMonth: PropTypes.func.isRequired,
  onChangeWeek: PropTypes.func.isRequired,
};

export default MonthYearPicker;
