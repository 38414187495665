import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingButton from '../../commonComponents/LoadingButton/LoadingButton';
import { config } from '../../config/Config';
import { axiosGet } from '../../helper/axios/axiosGet';
import useEventEnter from '../../hooks/useEventEnter';
import SearchInput from '../SearchInput';
import { customStyles, useColumn } from './useColumn';

export default function SubscriptionDrivers() {
  const tableHeight = window.innerHeight - 390;
  const { t } = useTranslation();
  const { columns } = useColumn();

  const [filters, setFilters] = useState({});
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [isLoadingAssign, setLoadingAssign] = useState(false);

  let { companyId, subscriptionId, companyName } = useParams();

  const getDataDrivers = async () => {
    setLoading(true);

    const { date, companyName, ...values } = filters;

    const newFilters = {
      ...values,
      companyId,
      createdAtFrom: date?.[0],
      createdAtTo: date?.[1],
      companyName: companyName?.label,
      limit: limit,
      page: currentPage,
    };

    const response = await axiosGet(`${config.API_URL.SUBSCRIPTION.GET_LIST_DRIVER_SUBSCRIPTION}`, {
      params: {
        ...newFilters,
      },
      headers: config.headers,
    });

    const { data = [], total } = response?.data;
    setDrivers(data);
    setTotal(total);
    setLoading(false);
  };

  const resetData = () => {
    setSelectedItem([]);
    getDataDrivers();
  };
  useEffect(() => {
    if (companyId) getDataDrivers();
  }, [limit, currentPage, companyId]);

  const handleFilter = (value, type) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };
    setFilters(newFilters);
  };

  const onApplyFilter = () => {
    setCurrentPage(1);
    getDataDrivers();
  };

  useEventEnter(onApplyFilter);

  const driver_ids = useMemo(() => {
    let ids = [];
    ids = [];
    selectedItem.map((item) => {
      ids.push(item._id);
    });
    return ids;
  }, [selectedItem]);

  const onSubmit = async () => {
    try {
      if (isLoadingAssign) return;
      setLoadingAssign(true);
      await axios.post(
        `${config.API_URL.SUBSCRIPTION.GET_LIST_DRIVER_SUBSCRIPTION_ASSIGN}`,
        {
          subscription_id: subscriptionId,
          driver_ids,
        },
        {
          headers: config.headers,
        }
      );
      toast(t('assign subscription success'));
      resetData();
      setLoadingAssign(false);
    } catch (error) {
      setLoadingAssign(false);
      toast.error(t(`assign subscription fail`));
    }
  };
  return (
    <div>
      <div className="flex flex-row items-center justify-end w-full gap-x-2">
        <div className="flex justify-end h-full py-3">
          <div class="group relative  flex justify-center">
            <button
              disabled={selectedItem.length <= 0 || isLoadingAssign}
              className="opacity-100 refresh-button"
              type="button"
              onClick={onSubmit}
            >
              <div className="flex flex-row items-center justify-center gap-x-4">
                <p>{t('assign_subscription')}</p>
                {isLoadingAssign && (
                  <div className="flex items-center justify-center h-full">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </button>
            <span class="absolute top-16 transition-opacity z-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
              {t('subscription_select_one')}
            </span>
          </div>

          <Link to={{ pathname: `/subscriptions` }}>
            <button className="opacity-100 refresh-button" type="button">
              {t('subscriptions')}
            </button>
          </Link>
        </div>
        <div>
          <h1 className="m-8 text-3xl">
            <span className="text-3xl font-bold">{companyName} </span>:اسم الشركة
          </h1>
        </div>
      </div>
      <div className="flex flex-wrap items-end justify-end gap-4 px-8 py-5">
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('name')}
            handleSearch={(e) => handleFilter(e, 'driverName')}
            makeRerender={filters}
            value={filters?.driverName}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('phone')}
            handleSearch={(e) => handleFilter(e, 'driverPhone')}
            makeRerender={filters}
            value={filters?.driverPhone}
          />
        </div>

        <div className="input-select-wrapper ltr">
          <DateTimeRangePicker
            className="input-select-input-wrapper !border-0 w-[200px]"
            disabled={loading}
            value={filters?.date}
            onChange={(value) => handleFilter(value, 'date')}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
      </div>
      <div className="flex items-start justify-end w-full px-8 pb-8">
        <div className="">
          <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={drivers}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[10, 20, 40, 60, 100]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(+currentRowsPerPage)}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        selectableRowSelected={(row) => {
          return row.isSelected;
        }}
        onSelectedRowsChange={(row) => {
          setSelectedItem(row.selectedRows);
        }}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowsHighlight
        subHeader
        expandOnRowClicked
        expandableRowsHideExpander
        direction={'rtl'}
        customStyles={customStyles}
      />
    </div>
  );
}
