import { useTranslation } from 'react-i18next';
import { TYPE_API } from '../LogsCitc/Schema';

const useFilterOptions = () => {
  const { t } = useTranslation();

  const status = [
    {
      value: false,
      label: t('failure'),
    },
    {
      value: true,
      label: t('success'),
    },
  ];
  const methods = [
    {
      value: 'GET',
      label: 'GET',
    },
    {
      value: 'POST',
      label: 'POST',
    },
    {
      value: 'PUT',
      label: 'PUT',
    },
    {
      value: 'PATCH',
      label: 'PATCH',
    },
    {
      value: 'DELETE',
      label: 'DELETE',
    },
  ];

  const operations = [
    {
      value: 'ORDER_ASSIGN',
      label: TYPE_API.ORDER_ASSIGN,
    },
    {
      value: 'ORDER_CREATE',
      label: TYPE_API.ORDER_CREATE,
    },
    {
      value: 'ORDER_CANCEL',
      label: TYPE_API.ORDER_CANCEL,
    },
    {
      value: 'DRIVER_EXECUTE_ORDER',
      label: TYPE_API.DRIVER_EXECUTE_ORDER,
    },
    {
      value: 'DRIVER_ASSIGN_DRIVER_TO_ORDER',
      label: TYPE_API.DRIVER_ASSIGN_DRIVER_TO_ORDER,
    },
    {
      value: 'DRIVER_ACCEPT_ORDER',
      label: TYPE_API.DRIVER_ACCEPT_ORDER,
    },
    {
      value: 'DRIVER_CREATE',
      label: TYPE_API.DRIVER_CREATE,
    },
    {
      value: 'DRIVER_EDIT',
      label: TYPE_API.DRIVER_EDIT,
    },
    {
      value: 'DRIVER_DEACTIVATE',
      label: TYPE_API.DRIVER_DEACTIVATE,
    },
    {
      value: 'DRIVER_GET',
      label: TYPE_API.DRIVER_GET,
    },
  ];

  return { methods, operations, status };
};

export default useFilterOptions;
