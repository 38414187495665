import { useContext } from 'react';
import { MapTrackingContext } from '../context/MapTrackingContext';

const useMapTracking = () => {
  const {
    mapTrackingData,
    onSetMapTrackingData,
    handlerDriverId,
    onResetDriverFocus,
    handlerSelectedDriver,
    center,
    drivers,
    setDrivers,
    polygon,
    polygonCompleted,
    orders,
    setOrders,
    handlerFocusOrder,
    orderId,
    orderFocus,
    setOrderFocus,
    driverId,
    driverFocus,
    fitBoundsCenter,
    onChange,
    refKeywordSearch,
    refSearchDriver,
    setLoadingDrivers,
    setLoadingOrders,
    loadingDrivers,
    loadingOrders,
    isRenderNewMaps,
    onChangeFieldFilterOrder,
    filtersOrder,
    onResetFilterOrder,
    boundaries,
    map,
    setMap,
    getBounds,
    onGetCurrentBoundary,
    setBoundaries,
  } = useContext(MapTrackingContext);

  return {
    mapTrackingData,
    onSetMapTrackingData,
    handlerDriverId,
    onResetDriverFocus,
    handlerSelectedDriver,
    center,
    drivers,
    setDrivers,
    polygon,
    polygonCompleted,
    orders,
    setOrders,
    handlerFocusOrder,
    orderId,
    orderFocus,
    setOrderFocus,
    driverId,
    driverFocus,
    fitBoundsCenter,
    onChange,
    refKeywordSearch,
    refSearchDriver,
    setLoadingDrivers,
    setLoadingOrders,
    loadingDrivers,
    loadingOrders,
    isRenderNewMaps,
    onChangeFieldFilterOrder,
    filtersOrder,
    onResetFilterOrder,
    boundaries,
    map,
    setMap,
    getBounds,
    onGetCurrentBoundary,
    setBoundaries,
  };
};

export default useMapTracking;
