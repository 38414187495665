import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { config } from '../../../config/Config';
import { IconClose, IconSpin } from '../../../constants/Icon';
import { handleMessage } from '../../../helper/utils';

function NotificationModalMap({ visible, toggleModal, drivers }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      driver_ids: [''],
    },
    validationSchema: Yup.object({
      title: Yup.string().trim().required(t('send_noti.validate.required.title')),
      message: Yup.string().trim().required(t('send_noti.validate.required.message')),
    }),
    validateOnChange: true,
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  function closeModal() {
    setIsOpen(false);
    toggleModal(false);
    formik.resetForm();
    formik.setErrors({});
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (visible) {
      openModal();
    }
  }, [visible]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const driverIds = drivers.map((data) => data.driver_id);
    let finalizeData = {
      title: formik.values.title,
      message: formik.values.message,
      driver_ids: driverIds,
    };

    try {
      const { data } = await axios.post(
        `${config.API_URL.NOTIFICATION.MESSAGE_DRIVERS_MAP}`,
        finalizeData,
        {
          headers: config.headers,
        }
      );
      if (data?.success) {
        handleMessage('success', t('send_noti.toast_mess.successed'));
        closeModal();
      } else {
        handleMessage('error', t('send_noti.toast_mess.failed'));
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Axios error', error);
      handleMessage('error', t('send_noti.toast_mess.failed'));
      setIsLoading(false);
    }
  };

  const renderNotiModal = () => {
    if (isLoading) {
      return (
        <div className="inline-block w-full max-w-5xl h-[calc(100vh-50vh)] overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-right align-middle transition-all transform bg-white shadow-xl rounded-2xl">
          <div className="flex items-center justify-center h-full w-full gap-x-4">
            <IconSpin />
            <p>{t('loading_data')}</p>
          </div>
        </div>
      );
    }
    return (
      <form
        onSubmit={formik.handleSubmit}
        className="inline-block w-full max-w-5xl overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-right align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl"
      >
        <div className="flex items-center justify-between gap-x-4">
          <h3 className="text-3xl font-bold"> {t('send_noti.notification')} </h3>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>

        <div className="flex flex-col ">
          <div className="my-10 text-right flex flex-col gap-y-10 overflow-y-auto transition-all">
            <div className="gap-x-4">
              <p className="text-center font-bold py-6 text-2xl">Driver near: {drivers.length}</p>
              <div className="flex justify-between pl-0.5 mb-4">
                <p className="font-bold py-6"> {t('send_noti.title')} </p>
                <div className="w-9/12">
                  <input
                    type="text"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    className="rounded-lg my-2 p-4 w-full border border-slate-300 text-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                  />
                  {formik.errors.title && formik.touched.title && (
                    <p className="flex justify-end text-red-500 pt-2 pb-6 text-xl font-bold float-right">
                      {formik.errors.title}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-between pl-0.5">
                <p className="font-bold py-6"> {t('send_noti.message')} </p>
                <div className="w-9/12">
                  <textarea
                    className="rounded-lg my-2 p-4 w-full border border-slate-300 text-md shadow-sm focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 resize-none text-justify"
                    cols="30"
                    rows="5"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.errors.message && formik.touched.message && (
                    <p className="flex justify-end text-red-500 pt-2 pb-6 text-xl font-bold float-right">
                      {formik.errors.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center w-full">
            <button className="btn-primary rounded-md px-3 py-4 " type="submit">
              {t('send_noti.submit')}
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center rtl">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {renderNotiModal()}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default NotificationModalMap;
