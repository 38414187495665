import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IconInformation } from '../../constants/Icon';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { config, SETTING_KEY } from '../../config/Config';

const init = {
  delay_time: false,
  step_distance: false,
  max_distance: false,
  init_distance: false,
  max_scan_driver: false,
  number_of_sending_driver: false,
};

export default function SettingODM({ isOpen, setIsOpen }) {
  const { t } = useTranslation();

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [valueSettingODM, setValueSettingODM] = useState({});
  const [openInformation, setOpenInformation] = useState(init);

  const getSetting = async () => {
    const { data } = await axios.get(config.API_URL.SETTING.GET, {
      params: {
        key: SETTING_KEY.SEND_ORDER_FILTERING,
      },
      headers: config.headers,
    });
    const { setting } = data;
    setValueSettingODM(setting);
  };

  const handleKeyDown = (event) => {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  };

  const onChangeInput = (name, e) => {
    const newValue = { ...values, [name]: e.target.value };
    setValues(newValue);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const settingData = [
        {
          key: SETTING_KEY.SEND_ORDER_FILTERING,
          content: {
            delay_time: Number(values.delay_time),
            step_distance: Number(values.step_distance),
            max_distance: Number(values.max_distance),
            init_distance: Number(values.init_distance),
            max_scan_driver: Number(values.max_scan_driver),
            number_of_sending_driver: Number(values.number_of_sending_driver),
          },
        },
      ];
      const resp = await axios.post(
        config.API_URL.SETTING.CREATE,
        {
          data: settingData,
        },
        {
          headers: config.headers,
        }
      );

      if (resp.status) {
        toast(t('data_saved_successfully'), { position: toast.POSITION.BOTTOM_LEFT });
      } else {
        toast.error(t('request_assigned_to_driver'), { position: toast.POSITION.BOTTOM_LEFT });
      }
    } catch (errors) {
    } finally {
      setLoading(false);
    }
  };

  const onBlur = () => {
    setOpenInformation(init);
  };

  useEffect(() => {
    getSetting();
  }, []);

  useEffect(() => {
    const newValues = {
      delay_time: Number(valueSettingODM.delay_time),
      step_distance: Number(valueSettingODM.step_distance),
      max_distance: Number(valueSettingODM.max_distance),
      init_distance: Number(valueSettingODM.init_distance),
      max_scan_driver: Number(valueSettingODM.max_scan_driver),
      number_of_sending_driver: Number(valueSettingODM.number_of_sending_driver),
    };
    setValues(newValues);
  }, [valueSettingODM]);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto rtl" onClose={closeModal}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block p-6 h-full overflow-x-hidden overflow-y-auto my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h1"
                  className="font-bold text-4xl leading-6 text-gray-900 text-center pb-4 border-b mb-6"
                >
                  Setting Order Distribution
                </Dialog.Title>

                <div className="p-6">
                  <div className="w-[400px] mb-6">
                    <div className="mb-2 font-semibold text-right flex gap-1 items-center">
                      Delay time
                      <div className="relative flex items-center">
                        <button
                          onClick={() => setOpenInformation({ delay_time: true })}
                          onBlur={onBlur}
                        >
                          <IconInformation />
                        </button>

                        {openInformation.delay_time && (
                          <div className="px-3 py-2 w-[200px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                            The waiting time for a driver to accept an order
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                      <input
                        onKeyDown={handleKeyDown}
                        value={values.delay_time}
                        onChange={(e) => onChangeInput('delay_time', e)}
                        type="number"
                        className="w-full outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-[400px] mb-6">
                    <div className="mb-2 font-semibold text-right flex gap-1 items-center">
                      Step distance
                      <div className="relative flex items-center">
                        <button
                          onClick={() => setOpenInformation({ step_distance: true })}
                          onBlur={onBlur}
                        >
                          <IconInformation />
                        </button>

                        {openInformation.step_distance && (
                          <div className="px-3 py-2 w-[200px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                            The increment distance for each driver scan
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                      <input
                        value={values.step_distance}
                        onChange={(e) => onChangeInput('step_distance', e)}
                        type="number"
                        className="w-full outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-[400px] mb-6">
                    <div className="mb-2 font-semibold text-right flex gap-1 items-center">
                      Max distance
                      <div className="relative flex items-center">
                        <button
                          onClick={() => setOpenInformation({ max_distance: true })}
                          onBlur={onBlur}
                        >
                          <IconInformation />
                        </button>

                        {openInformation.max_distance && (
                          <div className="px-3 py-2 w-[200px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                            The maximum radius within which the system can scan
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                      <input
                        value={values.max_distance}
                        onChange={(e) => onChangeInput('max_distance', e)}
                        type="number"
                        className="w-full outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-[400px] mb-6">
                    <div className="mb-2 font-semibold text-right flex gap-1 items-center">
                      Init distance
                      <div className="relative flex items-center">
                        <button
                          onClick={() => setOpenInformation({ init_distance: true })}
                          onBlur={onBlur}
                        >
                          <IconInformation />
                        </button>

                        {openInformation.init_distance && (
                          <div className="px-3 py-2 w-[200px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                            The radius for the initial scan
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                      <input
                        value={values.init_distance}
                        onChange={(e) => onChangeInput('init_distance', e)}
                        type="number"
                        className="w-full outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-[400px] mb-6">
                    <div className="mb-2 font-semibold text-right flex gap-1 items-center">
                      Max scan driver
                      <div className="relative flex items-center">
                        <button
                          onClick={() => setOpenInformation({ max_scan_driver: true })}
                          onBlur={onBlur}
                        >
                          <IconInformation />
                        </button>

                        {openInformation.max_scan_driver && (
                          <div className="px-3 py-2 w-[200px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                            The maximum number of drivers the system will search for in each scan
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                      <input
                        onKeyDown={handleKeyDown}
                        value={values.max_scan_driver}
                        onChange={(e) => onChangeInput('max_scan_driver', e)}
                        type="number"
                        className="w-full outline-none"
                      />
                    </div>
                  </div>

                  <div className="w-[400px] mb-6">
                    <div className="mb-2 font-semibold text-right flex gap-1 items-center">
                      Number of sending driver
                      <div className="relative flex items-center">
                        <button
                          onClick={() => setOpenInformation({ number_of_sending_driver: true })}
                          onBlur={onBlur}
                        >
                          <IconInformation />
                        </button>

                        {openInformation.number_of_sending_driver && (
                          <div className="px-3 py-2 w-[200px] border shadow-xl rounded-lg bg-white absolute -top-12 right-10 z-50">
                            The number of drivers notified about a new order in each scan
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-3 py-2 mb-[5px] rounded-md border items-center">
                      <input
                        onKeyDown={handleKeyDown}
                        value={values.number_of_sending_driver}
                        onChange={(e) => onChangeInput('number_of_sending_driver', e)}
                        type="number"
                        className="w-full outline-none"
                      />
                    </div>
                  </div>

                  <button
                    disabled={loading}
                    onClick={onSubmit}
                    className={`${
                      loading ? 'bg-gray-300' : 'bg-green-600 hover:bg-green-700 '
                    } h-[35px] w-full mb-4 focus:outline-none text-white focus:ring-1 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-700 font-medium rounded-lg text-xl px-12 py-2.5`}
                  >
                    Send
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
