import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Pagination from '../../commonComponents/Pagination';
import { config } from '../../config/Config';
import { useParams } from 'react-router-dom';
import { useDate } from '../../hooks/useDate';
import { FORMAT_DATE, parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { handleMessage } from '../../helper/utils';
import { DRIVER_HISTORY_TYPE } from '../../constants/constants';

const renderHistory = (values, t) =>
  values?.map((value) => {
    const { currentTimezone } = useDate();

    return (
      <div key={value._id} className="mt-4 rounded-lg border border-greyish-200 p-3 md:p-6">
        <div className="border-b pb-4">
          <div className="flex items-center gap-6">
            <p className="w-fit text-2xl font-bold">Update by</p>
            <p>{value.update_by.name}</p>
          </div>
          <div className="flex items-center gap-6">
            <p className="w-fit text-2xl font-bold">Role</p>
            <p>{value.update_by.role}</p>
          </div>
          <div className="flex items-center gap-6">
            <p className="w-fit text-2xl font-bold">Date update</p>
            <div className="flex gap-6">
              <p>
                {parseDateWithGmt({
                  date: value.created_at,
                  timezone: currentTimezone?.timezone,
                  format: FORMAT_DATE.hms,
                })}
              </p>
              <p>
                {parseDateWithGmt({
                  date: value.created_at,
                  timezone: currentTimezone?.timezone,
                  format: FORMAT_DATE.ymd,
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex items-center gap-6">
          <p className="w-fit text-2xl font-bold">Note</p>
          <p>{value?.update_content?.note}</p>
        </div>
      </div>
    );
  });

export default function CreateNote() {
  const { t } = useTranslation();
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [history, setHistory] = useState();
  const [valueNote, setValueNote] = useState('');
  const [detailDriver, setDetailDriver] = useState();

  const handleClickPage = (value) => {
    setCurrentPage(value + 1);
  };

  const getDriver = async () => {
    try {
      const resp = await axios.get(config.API_URL.DRIVERS.EDIT_DRIVER + id, {
        headers: config.headers,
      });
      setDetailDriver(resp.data);
    } catch (error) {
      console.log('🚀 ~ getDriver ~ error:', error);
    }
  };

  const getVersionHistory = async (page) => {
    try {
      const { data } = await axios.get(`${config.API_URL.DRIVERS.GET_VERSION_HISTORY(id)}`, {
        params: { limit: 5, page: page, type: DRIVER_HISTORY_TYPE.NOTE },
        headers: config.headers,
      });

      setHistory(data);
    } catch (error) {
      console.log('🚀 ~ getVersionHistory ~ error:', error);
    }
  };

  const handleOnchange = (content) => {
    setValueNote(content.target.value);
  };

  const handleCreateNote = async () => {
    try {
      await axios.post(
        `${config.API_URL.DRIVERS.CREATE_NOTE(id)}`,
        { note: valueNote },
        {
          headers: config.headers,
        }
      );
      handleMessage('success', 'Create note success');
      setValueNote('');
      getVersionHistory(currentPage);
    } catch (error) {
      console.log('🚀 ~ handleCreateNote ~ error:', error);
      handleMessage('error', t('error'));
    }
  };

  useEffect(() => {
    getDriver();
  }, []);

  useEffect(() => {
    getVersionHistory(currentPage);
  }, [currentPage]);

  return (
    <div className="rtl p-10">
      <div className="w-1/2 border rounded-lg p-6 mb-10">
        <p className="font-bold text-3xl mb-4">Create note</p>
        <p className="font-bold mb-3">Content</p>
        <textarea
          rows="4"
          value={valueNote}
          className="border mb-3 w-full p-4"
          onChange={handleOnchange}
        />
        <button
          disabled={!valueNote}
          className="registerBtn px-5 py-[7px] rounded-md disabled:bg-gray-300"
          onClick={handleCreateNote}
        >
          {t('create')}
        </button>
      </div>

      {history?.data?.length > 0 && (
        <div className="w-full rounded-lg border p-6">
          <p className="mb-4 text-3xl font-bold">{detailDriver?.name} :Notes for driver</p>
          {renderHistory(history?.data, t)}

          <div className="mt-5 flex justify-start">
            <Pagination page={currentPage} pages={history?.totalPage} onClick={handleClickPage} />
          </div>
        </div>
      )}
    </div>
  );
}
