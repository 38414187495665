import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../DropdownInput';
import { config, SETTING_KEY } from '../../config/Config';

const ConfigAPIPaymentPlatform = ({ apiPaymentPlatform, setApiPaymentPlatform }) => {
  const { t } = useTranslation();
  const [defaultPayment, setDefaultPayment] = useState(null);

  const paymentPlatform = [
    { value: 'HYPERPAY', label: t('HYPERPAY') },
    { value: 'PAYFORT', label: t('PAYFORT') },
    { value: 'OFF', label: t('OFF') },
  ];

  const handleSelectPayment = (value) => {
    const newFilters = {
      ...apiPaymentPlatform,
      payment: value.value,
    };
    setApiPaymentPlatform(newFilters);
  };

  const getSetting = async () => {
    try {
      const resp = await axios.get(config.API_URL.SETTING.GET, {
        params: {
          key: SETTING_KEY.PAYMENT_PLATFORM,
        },
        headers: config.headers,
      });
      const { data } = resp;
      const { setting } = data;
      if (setting) {
        const configPayment = {
          payment: setting.payment,
        };
        const filtered = paymentPlatform.filter((ver) => ver.value === setting.payment)?.[0];
        setDefaultPayment(filtered);
        handleSelectPayment(configPayment);
      }
    } catch (e) {
      console.log('getSetting ~ e:', e);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div className="setting">
      <div className="row flex items-center gap-x-4">
        <label>{t('payment_platform')}: </label>
        <DropdownInput
          textAlign="right"
          placeholder={t('payment_platform')}
          options={paymentPlatform}
          handleSelect={handleSelectPayment}
          defaultSelectedValue={defaultPayment}
          hasDefault={false}
        />
      </div>
    </div>
  );
};

export default ConfigAPIPaymentPlatform;
