import React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IconClose, Start } from '../../constants/Icon';
import { isEmpty } from 'lodash';
import { renderRatingColor } from '../../constants/constants';

export default function DetailFeedback({ visible, dataFocus, toggleModal }) {
  const { t } = useTranslation();

  function closeModal() {
    toggleModal(false);
  }

  const renderRatingDetail = () => {
    return (
      <div className="inline-block w-full max-w-7xl h-full overflow-x-hidden overflow-y-hidden px-10 py-12 my-8 text-left align-middle transition-all duration-300 transform bg-white shadow-xl rounded-2xl">
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex items-center gap-x-4">
            <span className="text-3xl font-bold">{t('feedback_detail')}</span>
          </div>
          <button type="button" onClick={closeModal}>
            <IconClose />
          </button>
        </div>
        <div
          className={`my-10 pl-6 text-right flex flex-col gap-y-10 overflow-y-auto  transition-all ${
            dataFocus?.images.length !== 0 && 'h-[calc(100vh-260px)]'
          }`}
        >
          <div>
            <div className="grid grid-cols-4 gap-x-4 mt-6 gap-y-3">
              <p className="font-bold text-2xl col-span-1">{t('shukah.customer.name')}</p>
              <p className="col-span-3 text-2xl">{dataFocus?.customer_name}</p>
              <p className="font-bold col-span-1">{t('shukah.customer.phone')}</p>
              <p className="col-span-3">{dataFocus?.contact_phone}</p>
              <p className="font-bold col-span-1">{t('id_number')}</p>
              <p className="col-span-3">{dataFocus?.id_number}</p>
              <p className="font-bold col-span-1">{t('order_number')}</p>
              <p className="col-span-3">{dataFocus?.order_number}</p>
              {dataFocus?.driver_star && (
                <>
                  <p className="font-bold col-span-1">{t('driver_star')}</p>
                  <p className="col-span-3 flex flex-row items-center justify-start">
                    <span className="w-6 h-6 mx-1">
                      <Start color={renderRatingColor(dataFocus?.driver_star)} />
                    </span>
                    {dataFocus?.driver_star}
                  </p>
                </>
              )}
              {dataFocus?.driver_feedback && (
                <>
                  <p className="font-bold col-span-1">{t('driver_feedback')}</p>
                  <p className="col-span-3">{dataFocus?.driver_feedback}</p>
                </>
              )}
              {dataFocus?.store_star && (
                <>
                  <p className="font-bold col-span-1">{t('store_star')}</p>
                  <p className="col-span-3 flex flex-row items-center justify-start">
                    <span className="w-6 h-6 mx-1">
                      <Start color={renderRatingColor(dataFocus?.store_star)} />
                    </span>
                    {dataFocus?.store_star}
                  </p>
                </>
              )}

              {dataFocus?.store_feedback && (
                <>
                  <p className="font-bold col-span-1">{t('store_feedback')}</p>
                  <p className="col-span-3">{dataFocus?.store_feedback}</p>
                </>
              )}
              <p className="font-bold col-span-1">{t('attachment')}</p>

              {!isEmpty(dataFocus?.images) &&
                dataFocus?.images?.map((item) => (
                  <div className="col-span-4 flex mt-6" key={item?.ETag}>
                    <div className="flex-row">
                      <a target="_blank" rel="noopener noreferrer" href={item}>
                        <img className="rounded-md" src={item} alt="" />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {dataFocus?.images.length === 0 && (
          <p className="flex justify-center mt-10">{t('orders_no_records')}</p>
        )}
      </div>
    );
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {renderRatingDetail()}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
