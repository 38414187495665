import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { config } from '../../config/Config';

import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { Start } from '../../constants/Icon';
import SearchInput from '../SearchInput';
import { customStyles, newUrl, useColumn } from './useColumn';
import LoadingButton from '../../commonComponents/LoadingButton';
import useEventEnter from '../../hooks/useEventEnter';
import { axiosGet } from '../../helper/axios/axiosGet';
import moment from 'moment';
import axiosInstance from '../../helper/axios/axiosInstance';

const DriverRating = ({
  type,
  filters,
  setFilters,
  meta,
  setMeta,
  setCurrentPage,
  currentPage,
  limit,
  setLimit,
  star,
  setStar,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const tableHeight = window.innerHeight - 390;
  const { columns } = useColumn();
  const history = useHistory();

  const [selectedId, setSelectedId] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [isExportRatingDrivers, setIsExportRatingDrivers] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataRating = async () => {
    setLoading(true);
    const newFilterParams = {
      'filter.toStar': star.max,
      'filter.fromStar': star.min,
      'filter.phone': filters.phone,
      'filter.name': filters.name,
    };
    const newFilters = {
      filters: {
        ...filters,
      },
      sort: -1,
      limit: limit,
      page: currentPage,
    };

    window.history.replaceState(
      { path: newUrl(newFilters, star, type) },
      '',
      newUrl(newFilters, star, type)
    );

    const response = await axiosGet(`${config.API_URL.RATING.RATING_OPERATION_DRIVER}`, {
      params: {
        ...newFilterParams,
        sort: -1,
        limit: limit,
        page: currentPage,
      },
      headers: config.headers,
    });
    const { data = [], meta = {} } = response?.data;
    setRatings(data);
    setMeta(meta);
    setLoading(false);
  };

  useEffect(() => {
    getDataRating();
  }, [limit, currentPage, type]);

  const handleFilters = (key, value) => {
    const newFilters = {
      ...filters,
      [key]: value,
    };
    setFilters(newFilters);
  };

  const onApplyFilter = () => {
    setCurrentPage(1);

    getDataRating();
  };

  const exportSTCExcel = () => {
    setIsExportRatingDrivers(true);

    const newFilterParams = {
      'filter.toStar': star.max,
      'filter.fromStar': star.min,
      'filter.phone': filters.phone,
      'filter.name': filters.name,
      limit: limit,
      page: currentPage,
    };

    try {
      axiosInstance
        .post(config.API_URL.RATING.RATING_OPERATION_DRIVER_EXCEL, null, {
          params: {
            ...newFilterParams,
          },
          headers: {
            ...config.headers,
            'Content-Disposition': 'attachment; filename=template.xlsx',
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `rating-drivers${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setIsExportRatingDrivers(false);
        });
    } catch (errors) {
      setIsExportRatingDrivers(false);
    } finally {
    }
  };

  useEventEnter(onApplyFilter);

  return (
    <div className="">
      <div className="flex justify-end">
        <button
          disabled={isExportRatingDrivers}
          className={`refresh-button direction-r ${
            isExportRatingDrivers ? 'opacity-40 border-red-200' : 'opacity-100'
          }`}
          onClick={exportSTCExcel}
        >
          {t('export_excel_all_by_filter')}
        </button>
      </div>
      <div className="px-8 py-5 flex items-center gap-4 flex-wrap justify-end">
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('phone_number')}
            handleSearch={(e) => handleFilters('phone', e)}
            makeRerender={filters}
            value={filters?.phone}
          />
        </div>
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('driver_name')}
            handleSearch={(e) => handleFilters('name', e)}
            makeRerender={filters}
            value={filters?.name}
          />
        </div>
        <div className="flex flex-row justify-center items-center">
          <div className="px-6 flex flex-row justify-center items-center">
            {t('rating')}
            <div className="w-6 h-6 mx-1">
              <Start />
            </div>
          </div>
          <div className="input-select-wrapper w-[200px] ">
            <InputRange
              formatLabel={(value, type) => {
                if (['min', 'max'].indexOf(type) > -1) {
                  return '';
                } else return <div className="mt-[-8px]"> {value}</div>;
              }}
              maxValue={5}
              minValue={0}
              step={0.5}
              value={star}
              onChange={(value) => setStar(value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex items-start justify-end px-8 pb-8">
        <div className="">
          <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={ratings}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(+currentRowsPerPage)}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={meta?.totalItems}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        selectableRows
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedId((p) => [...p, row._id])
            : setSelectedId(selectedId.filter((i) => i !== row._id)),
        ]}
        onRowClicked={(row) =>
          history.push({
            pathname: '/rating-drivers-detail',
            search: `?id-number=${row?.id_number}`,
          })
        }
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        persistTableHead
        selectableRowSelected={(row) => row.isSelected}
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={customStyles}
      />
    </div>
  );
};

export default DriverRating;
