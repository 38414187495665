import axios from 'axios';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { config } from '../../config/Config';

import { parseQueryString } from '../../helper/utils';
import DetailFeedback from './DetailFeedback';
import { useColumn } from './useColumn';

const RatingStoreDetail = () => {
  const { search } = useLocation();
  const queryString = parseQueryString(search);
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const [dataFocus, setDataFocus] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([]);

  const merchant_id = queryString['merchant_id'];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataRating = async (filter) => {
    setLoading(true);
    const response = await axios.get(`${config.API_URL.RATING.RATING_OPERATION_RATING_START}`, {
      params: {
        ['filter.merchant_id']: merchant_id,
      },
      headers: config.headers,
    });

    console.log('🚀 ~ file: RatingStoreDetail.js:38 ~ getDataRating ~ response:', response);

    setRatings(response?.data);

    await setTotal(total);
    setLoading(false);
  };
  useEffect(() => {
    getDataRating();
  }, []);

  const tableHeight = window.innerHeight - 330;

  const { columns } = useColumn();
  const toggleModal = (visible) => {
    setModalVisible(visible);
    if (!isEmpty(dataFocus)) {
      setDataFocus(null);
    }
  };
  const handleToggleModal = (data) => {
    toggleModal(true);
    setDataFocus(data);
  };

  const storeName = ratings[0]?.store?.store_name ?? '';
  const store_id = ratings[0]?.store?.merchant_id ?? '';
  const between = storeName && store_id && '-';

  return (
    <div className="rtl">
      <p className="rtl font-medium text-3xl mr-8 mt-8 mb-4">
        {storeName}
        <span className="font-medium text-3xl mx-2">{between}</span>
        {store_id}
      </p>
      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={ratings}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[100, 200, 400, 600]}
        onChangeRowsPerPage={(currentRowsPerPage) => {
          setLimit(+currentRowsPerPage);
          setCurrentPage(1);
        }}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={total}
        paginationServer
        onRowClicked={(row) => handleToggleModal(row)}
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        paginationDefaultPage={currentPage}
        selectableRowsVisibleOnly
        selectableRowSelected={(row) => row.isSelected}
        persistTableHead
        selectableRowsHighlight
        direction={'rtl'}
        customStyles={{
          headCells: {
            style: {
              fontWeight: 'bold',
              fontSize: 12,
            },
          },
          rows: {
            style: {
              cursor: 'pointer',
              fontSize: 12,
            },
          },
        }}
      />
      <DetailFeedback visible={modalVisible} toggleModal={toggleModal} dataFocus={dataFocus} />
    </div>
  );
};

export default RatingStoreDetail;
