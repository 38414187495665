import { useTranslation } from 'react-i18next';
import { renderFields } from '../../constants/constants';
import { parseDateWithGmt } from '../../helper/date/parseDateGmt';
import { useDate } from '../../hooks/useDate';
const { useMemo } = require('react');

const useColumn = () => {
  const { t } = useTranslation();
  const { currentTimezone } = useDate();

  const columns = useMemo(
    () => [
      {
        name: t('subscription_title'),
        selector: 'subscriptionName',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.subscriptionName),
      },
      {
        name: t('subscription_status'),
        sortable: true,
        minWidth: '200px',
        selector: (row) => {
          return (
            <div
              className={`${
                row.status === 'ACTIVE' ? 'text-green-600' : 'text-gray-300'
              } font-semibold `}
            >
              {row.status || '--'}
            </div>
          );
        },
      },
      {
        name: t('description'),
        selector: 'description',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.description),
      },

      {
        name: t('status'),
        selector: 'status',
        sortable: true,
        minWidth: '200px',
        cell: (row) => t(String(row.status).toLowerCase()),
      },
      {
        name: t('price'),
        selector: 'price',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.price),
      },

      {
        name: t('duration'),
        selector: 'duration',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.duration),
      },

      {
        name: t('company'),
        selector: 'company_info',
        sortable: true,
        minWidth: '200px',
        cell: (row) => renderFields(row?.company_info?.name),
      },
      {
        name: t('subscription_date'),
        selector: 'phone',
        sortable: true,
        minWidth: '200px',
        cell: (row) =>
          parseDateWithGmt({
            date: row.created_at,
            timezone: currentTimezone?.timezone,
          }),
      },
    ],
    []
  );
  return { columns };
};

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 12,
      background: '#F9F9F9',
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      fontSize: 12,
    },
  },
  header: {
    style: {
      minHeight: 0,
    },
  },
  subHeader: {
    style: {
      minHeight: 0,
    },
  },
};

export { customStyles, useColumn };
