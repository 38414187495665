import { useTranslation } from 'react-i18next';
import { IconSpin } from '../../constants/Icon';

export default function Loading() {
  const { t } = useTranslation();

  return (
    <div className="inline-block w-full max-w-7xl h-[calc(100vh-200px)] overflow-x-hidden overflow-y-auto px-10 py-12 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
      <div className="flex items-center justify-center h-full w-full gap-x-4">
        <IconSpin />
        <p>{t('loading')}</p>
      </div>
    </div>
  );
}
