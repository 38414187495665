import { Chart, registerables } from 'chart.js';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { driverChart } from './driverChart';
import { FORMAT_DATE, LOCATE_EN, TZ_ASIA_RIYADH } from '../../../constants/constants';

Chart.register(...registerables);
export const transformDataChart = (data = []) => {
  const formatDateTime = (time) =>
    moment(time).tz(TZ_ASIA_RIYADH).locale(LOCATE_EN).format(FORMAT_DATE);

  return data.reduce(
    (accumulator, currentValue) => {
      const checkValueLabels = currentValue?.day
        ? formatDateTime(currentValue?.end)
        : `${formatDateTime(currentValue?.start)} - ${formatDateTime(currentValue?.end)}`;
      const checkValueTotalHours = currentValue?.totalHours.toFixed(2);
      const checkValueTitle = currentValue?.position
        ? currentValue?.position
        : currentValue?.day
        ? currentValue?.day
        : currentValue?.month;

      accumulator['time'].push(checkValueLabels);
      accumulator['values'].push(checkValueTotalHours);
      accumulator['title'].push(checkValueTitle);

      return accumulator;
    },
    { time: [], values: [], title: [] }
  );
};

const useBarChart = ({ elementId, data }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isEmpty(data)) return;
    const dataChart = transformDataChart(data);
    const STRING_2D = '2d';
    var ctx = document.getElementById(elementId).getContext(STRING_2D);
    const hoursDriverChart = driverChart({ ctx: ctx, dataChart: dataChart, t: t });

    return () => {
      hoursDriverChart.destroy();
    };
  }, [data, elementId]);
  return {};
};
export default useBarChart;
