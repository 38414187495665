import axios from 'axios';
import { config } from '../../../config/Config';
import { DAY } from '../../../constants/constants';

const { useState, useEffect } = require('react');

const useLogicDrivers = (driverId) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const valueAmountWeekFirst = 4; //value default follow value first filter

  const [date, setDate] = useState({
    month: currentMonth + 1,
    year: currentYear,
    amount: valueAmountWeekFirst,
  });
  const [selectedOption, setSelectedOption] = useState(DAY);
  const [data, setData] = useState({});
  const onChangeDate = (key, value) => {
    setDate({ ...date, [key]: value });
  };

  const getData = async () => {
    try {
      const { data } = await axios.get(config.API_URL.DRIVERS.DRIVER_ANALYSIS(driverId), {
        params: {
          type: selectedOption,
          ...date,
        },
        headers: config.headers,
      });

      setData(data);
    } catch (error) {
      console.log('🚀 ~ file: useLogicDrivers.js:36 ~ getData ~ error:', error);
    }
  };
  useEffect(() => {
    getData();
  }, [selectedOption, date]);

  return { date, onChangeDate, selectedOption, setSelectedOption, data };
};
export default useLogicDrivers;
