import React, { useState } from 'react';
import SearchInput from '../SearchInput';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../commonComponents/LoadingButton/LoadingButton';
import DataTable from 'react-data-table-component';
import { customStyles, useColumn } from './useColumn';
import useEventEnter from '../../hooks/useEventEnter';
import { config } from '../../config/Config';
import { axiosGet } from '../../helper/axios/axiosGet';
import { useEffect } from 'react';
import CustomSelectReact from '../CustomSelectReact';
import AddSubScription from './AddSubscription';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import SubscriptionActions from './SubscriptionActions';
import DeleteSubscription from './DeleteSubscription';

export default function Subscriptions() {
  const tableHeight = window.innerHeight - 390;
  const { t } = useTranslation();
  const { columns } = useColumn();

  const [filters, setFilters] = useState({});
  const [meta, setMeta] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleDelete, setModalVisibleDelete] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [defaultSubscription, setDefaultSubscription] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDataSubscription = async () => {
    setLoading(true);

    const { date, companyName, ...values } = filters;

    const newFilters = {
      ...values,
      createdAtFrom: date?.[0],
      createdAtTo: date?.[1],
      companyName: companyName?.label,
      limit: limit,
      page: currentPage,
    };

    const response = await axiosGet(`${config.API_URL.SUBSCRIPTION.GET_LIST}`, {
      params: {
        ...newFilters,
      },
      headers: config.headers,
    });

    const { data = [], count } = response?.data;
    setSubscription(data?.results);
    setMeta(count);
    setLoading(false);
  };

  useEffect(() => {
    getDataSubscription();
  }, [limit, currentPage]);

  const getAllCompanies = async () => {
    try {
      const { data } = await axiosGet(`${config.API_URL.DELIVERY_COMPANY.LIST}`, {
        headers: config.headers,
      });

      if (data && data?.success) {
        const { data: response } = data;

        const terraformData = response?.map((company) => {
          return {
            label: company.name,
            value: company.name,
            id: company._id,
          };
        });

        setCompanies(terraformData);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleFilter = (value, type) => {
    const newFilters = {
      ...filters,
      [type]: value,
    };
    setFilters(newFilters);
  };

  const onApplyFilter = () => {
    setCurrentPage(1);
    getDataSubscription();
  };

  useEventEnter(onApplyFilter);

  const toggleModal = (visible) => {
    setModalVisible(visible);
  };

  const handleToggleModal = (data) => {
    setDefaultSubscription(data);
    toggleModal(true);
  };

  const toggleModalDelete = (visible) => {
    setModalVisibleDelete(visible);
  };

  const handleOpenDelete = (data) => {
    setDefaultSubscription(data);
    toggleModalDelete(true);
  };

  return (
    <>
      <div className="flex justify-end h-full py-3">
        {/* <Link to={{ pathname: `/subscriptions/drivers` }}>
          <button className="opacity-100 refresh-button" type="button">
            {t('driver_subscriptions')}
          </button>
        </Link> */}
        <button className="opacity-100 refresh-button" type="button" onClick={handleToggleModal}>
          {t('add_subscription')}
        </button>
      </div>
      <div className="flex flex-wrap items-end justify-end gap-4 px-8 py-5">
        <div className="input-select-wrapper">
          <SearchInput
            disabled={loading}
            placeholder={t('title')}
            handleSearch={(e) => handleFilter(e, 'subscriptionName')}
            makeRerender={filters}
            value={filters?.subscriptionName}
          />
        </div>
        <div className="flex">
          <CustomSelectReact
            isClearable
            isDisabled={loading}
            placeholder={t('company')}
            onChange={(value) => handleFilter(value, 'companyName')}
            className="filter-select min-w-[200px]"
            options={companies}
            value={filters.companyName}
          />
        </div>
        <div className="input-select-wrapper ltr">
          <DateTimeRangePicker
            className="input-select-input-wrapper !border-0 w-[200px]"
            disabled={loading}
            value={filters?.date}
            onChange={(value) => handleFilter(value, 'date')}
            disableClock
            format={'y-MM-dd'}
          />
        </div>
      </div>
      <div className="flex items-start justify-end w-full px-8 pb-8">
        <div className="">
          <LoadingButton onClick={onApplyFilter} label={t('Apply Filter')} loading={loading} />
        </div>
      </div>

      <DataTable
        noDataComponent={<div style={{ padding: '24px' }}>{t('orders_no_records')}</div>}
        highlightOnHover
        columns={columns}
        data={subscription}
        fixedHeader
        pagination
        contextMessage={{ singular: t('item'), plural: t('items'), message: t('selected') }}
        fixedHeaderScrollHeight={tableHeight.toString() + 'px'}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[10, 20, 40, 60]}
        onChangeRowsPerPage={(currentRowsPerPage) => setLimit(+currentRowsPerPage)}
        progressPending={loading}
        progressComponent={<div className="wait">{t('please_wait')}</div>}
        paginationTotalRows={meta}
        paginationServer
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          rowsPerPageText: t('total_record'),
          rangeSeparatorText: t('from'),
          noRowsPerPage: false,
          selectAllRowsItem: false,
        }}
        // selectableRows
        selectableRowSelected={(row) => row.isSelected}
        onSelectedRowsChange={(row) => setSelectedItem(row.selectedRows)}
        paginationDefaultPage={currentPage}
        onRowExpandToggled={(toggleState, row) => [
          toggleState
            ? setSelectedItem((p) => [...p, row._id])
            : setSelectedItem(selectedItem.filter((i) => i !== row._id)),
        ]}
        selectableRowsVisibleOnly
        persistTableHead
        subHeader
        expandOnRowClicked
        selectableRowsHighlight
        expandableRowsHideExpander
        direction={'rtl'}
        customStyles={customStyles}
        expandableRows
        expandableRowsComponent={
          <SubscriptionActions
            data={(row) => row}
            handlerModal={handleToggleModal}
            handleOpenDelete={handleOpenDelete}
          />
        }
      />

      <AddSubScription
        defaultSubscription={defaultSubscription}
        visible={modalVisible}
        toggleModal={toggleModal}
        companies={companies}
        getDataSubscription={getDataSubscription}
      />
      <DeleteSubscription
        visible={modalVisibleDelete}
        toggleModal={toggleModalDelete}
        defaultSubscription={defaultSubscription}
        getDataSubscription={getDataSubscription}
      />
    </>
  );
}
