import React from 'react';
import { useTranslation } from 'react-i18next';
import CsvDownload from 'react-json-to-csv';

export default function SendNotiSelect({
  selectedItem,
  toggleBtnSendNotiDriver,
  enableByListDriver,
  isLoading,
  odmDisableAll,
}) {
  const { t } = useTranslation();

  const driverIds = selectedItem.map((item) => item._id);

  return (
    <div className="w-full">
      {selectedItem.length > 0 && (
        <div className="absolute flex w-full flex-row-reverse gap-3 z-10 h-[56px] items-center pr-6 bg-[#e3f2fd]">
          <CsvDownload
            filename="drivers.csv"
            data={selectedItem}
            style={{
              // position: 'absolute',
              borderWidth: 0,
              backgroundColor: '#fff',
              padding: 10,
              borderRadius: 5,
              cursor: 'pointer',
              width: 120,
            }}
          >
            {t('loading_data')}
          </CsvDownload>
          <button
            onClick={toggleBtnSendNotiDriver}
            style={{
              borderWidth: 0,
              backgroundColor: '#fff',
              padding: 10,
              borderRadius: 5,
              cursor: 'pointer',
              width: 120,
            }}
          >
            Send notification
          </button>
          <button
            disabled={isLoading}
            onClick={() => enableByListDriver(driverIds)}
            className="disabled:bg-gray-400 p-4 bg-white rounded-md cursor-pointer w-auto"
          >
            Enable by list
          </button>
          <button
            disabled={isLoading}
            onClick={() => odmDisableAll(driverIds)}
            className="disabled:bg-gray-400 p-4 bg-white rounded-md cursor-pointer w-auto"
          >
            Disable all odm
          </button>
        </div>
      )}
    </div>
  );
}
