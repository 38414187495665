import React from 'react';
import SearchInput from '../SearchInput';
import CustomSelectReact from '../CustomSelectReact';
import LoadingButton from '../../commonComponents/LoadingButton/LoadingButton';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker';
import { useTranslation } from 'react-i18next';
import useFilterOptionOrder from '../Orders/NewOrder/Filters/filterOption';

export default function Filters({
  isLoading,
  handleChangeFilter,
  filters,
  dateRange,
  handleChangeDateOnFilter,
  handleGetDataByFilter,
}) {
  const { t } = useTranslation();
  const {
    filterPayment,
    filterExpress,
    filterIsDiscount,
    filterStatus,
    filterCompany,
    filterDelivery,
    filterGeidea,
    filterIsCasper,
    filterIsMissingOrder,
  } = useFilterOptionOrder();

  return (
    <div className="px-8 py-5 flex items-center gap-4 mb-[35px] flex-wrap">
      <div>
        <SearchInput
          disabled={isLoading}
          placeholder={t('customer')}
          handleSearch={(value) => handleChangeFilter(value, 'customer_name')}
          makeRerender={filters}
          value={filters?.customer_name}
        />
      </div>

      <div>
        <SearchInput
          disabled={isLoading}
          placeholder={t('order_number')}
          handleSearch={(value) => handleChangeFilter(value, 'order_number')}
          makeRerender={filters}
          value={filters?.order_number}
        />
      </div>

      <div>
        <SearchInput
          disabled={isLoading}
          placeholder={t('restaurant')}
          handleSearch={(value) => handleChangeFilter(value, 'restaurant')}
          makeRerender={filters}
          value={filters?.restaurant}
        />
      </div>

      <div>
        <SearchInput
          disabled={isLoading}
          placeholder={t('phone')}
          handleSearch={(value) => handleChangeFilter(value, 'phone')}
          makeRerender={filters}
          value={filters?.phone}
        />
      </div>

      <div>
        <SearchInput
          disabled={isLoading}
          placeholder={t('driver_name')}
          handleSearch={(value) => handleChangeFilter(value, 'driver_name')}
          makeRerender={filters}
          value={filters?.driver_name}
        />
      </div>

      <div className="flex">
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('payment')}
          onChange={(value) => handleChangeFilter(value, 'payment')}
          className="filter-select min-w-[200px]"
          options={filterPayment}
          value={filters?.payment}
          isMulti
        />
      </div>

      <div>
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('isFreeExpress')}
          className="checkbox-order w-[200px] filter-select"
          options={filterExpress}
          value={filters?.isFreeExpress}
          onChange={(value) => handleChangeFilter(value, 'isFreeExpress')}
        />
      </div>

      <div>
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('is_show_discount')}
          className="checkbox-order w-[200px] filter-select"
          options={filterIsDiscount}
          value={filters?.isDiscount}
          onChange={(value) => handleChangeFilter(value, 'isDiscount')}
        />
      </div>

      <div>
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('status')}
          className="checkbox-order w-[200px] filter-select"
          options={filterStatus}
          value={filters?.status}
          onChange={(value) => handleChangeFilter(value, 'status')}
          isMulti
        />
      </div>

      <div>
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('company')}
          className="checkbox-order w-[200px] filter-select"
          options={filterCompany}
          value={filters?.from_company}
          onChange={(value) => handleChangeFilter(value, 'from_company')}
          isMulti
        />
      </div>

      <div>
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('delivery_company_filter')}
          className="checkbox-order w-[200px] filter-select"
          options={filterDelivery}
          value={filters?.driver_company}
          onChange={(value) => handleChangeFilter(value, 'driver_company')}
          isMulti
        />
      </div>

      <div>
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={t('geidea')}
          className="checkbox-order w-[200px] filter-select"
          options={filterGeidea}
          value={filters?.use_geidea}
          onChange={(value) => handleChangeFilter(value, 'use_geidea')}
        />
      </div>

      <div className="flex">
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={'isCasper'}
          className="checkbox-order w-[200px] filter-select"
          options={filterIsCasper}
          value={filters?.isCasper}
          onChange={(value) => handleChangeFilter(value, 'isCasper')}
        />
      </div>

      <div className="flex">
        <CustomSelectReact
          isClearable
          isDisabled={isLoading}
          placeholder={'isMissingOrder'}
          className="checkbox-order w-[200px] filter-select"
          options={filterIsMissingOrder}
          value={filters?.isMissingOrder}
          onChange={(value) => handleChangeFilter(value, 'isMissingOrder')}
        />
      </div>

      <div className="input-select-wrapper" style={{ direction: 'ltr' }}>
        <DateTimeRangePicker
          disabled={isLoading}
          className="input-select-input-wrapper !border-0 w-[200px]"
          value={dateRange}
          onChange={handleChangeDateOnFilter}
          disableClock
          format={'y-MM-dd'}
        />
      </div>

      <div className="relative w-full mb-4">
        <div className="absolute right-0 ">
          <LoadingButton
            onClick={handleGetDataByFilter}
            label={t('Apply Filter')}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
